import React from 'react';
import { DigitalAnimation } from "./animation/digitalAnimation";
import Marquee from "react-fast-marquee";

import company1 from "../assets/Deloitte.svg";
import company2 from "../assets/notice.svg";
import company3 from "../assets/Samurai.svg";
import company4 from "../assets/Taskade.svg";
import company5 from "../assets/alfan.svg";

export function Clients(){
//     return  <DigitalAnimation>
//     <section className="logo-section pt-20">
//       <div className="container mx-auto px-4">
//         <div className="grid w-100 justify-center mt-8">
//       {/* <Marquee>
//        { [company1,company2,company3,company4,company5,company6, company7,
       
//          company1,company2,company3,company4,company5,company6, company7].map(m=><div  className="px-5"><a href="#">
//             <img src={m} className="h-11" />
//           </a></div>)}
// </Marquee> */}
//            <a href="#" className="p-6 md:p-8 md:py-11">
//             <img src={company1} />
//           </a>
//           <a href="#" className="p-6 md:p-8 md:py-11">
//             <img src={company2} />
//           </a>
//           <a href="#" className="p-6 md:p-8 md:py-11">
//             <img src={company3} />
//           </a>
//           <a href="#" className="p-6 md:p-8 md:py-11">
//             <img src={company4} />
//           </a>
//           <a href="#" className="p-6 md:p-8 md:py-11">
//             <img src={company5} />
//           </a>
//           <a href="#" className="p-6 md:p-8 md:py-11">
//             <img src={company6} />
//           </a> 
//         </div>
//       </div>
//     </section>
//     </DigitalAnimation>
return   <DigitalAnimation>
<section className="logo-section pt-16 px-4">
  <div className="container mx-auto">
    {/* <div className="text-center text-xl">
      Trusted by 50+ companies.{" "}
    </div> */}
    <div className="grid grid-cols-3 md:grid-cols-5 justify-center ">
    {  [company1,company2,company3,company4,company5].map(m=><a href="#" className="p-6 md:p-8 md:py-11">
        <img style={{height:"48px"}} src={m} />
      </a>)}
     
    </div>
  </div>
</section>
</DigitalAnimation>

}


import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./homePage";
import { AboutPage } from "./aboutPage";
// import { ContactPage } from "./contactPage";
import { TermandConditionPage } from "./termsAndConditionPage";
import React from "react";
import { PrivacyPolicy } from "./privacyPolicy";


function App() {

  return (
    <React.StrictMode>

      <BrowserRouter >
        <Routes >

          <Route path="/" element={<HomePage />}></Route>
          <Route path="/about" element={<AboutPage />} />
          {/* <Route path="/contact" element={<ContactPage />} /> */}
          <Route path="/terms-and-condition" element={<TermandConditionPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route path="/*" element={<HomePage />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default App;

// import { Link } from "react-router-dom";
// import slider2 from "../assets/slide-2.png";
import React from 'react';
import { DigitalAnimation } from "./animation/digitalAnimation";
import { LottoIcon } from "./helper/lottoIcon";


export function MemberShip() {
  return <div>
    <section className="py-16 md:py-24 px-4 md:mt-16" id="benefits">
      <div className="container mx-auto">
        <DigitalAnimation>
          <h2 className="text-black text-5xl md:text-6xl font-bold">
          Plan Benefits
            </h2>
          <p className="text-lg text-black max-w-xl mx-auto mt-6">
          you’ll have no reason to look elsewhere for your design needs
          </p>
        </DigitalAnimation>
        <DigitalAnimation>
          <div className="text-center inline-block mt-6 mb-10">
            <a
              href="#price"
              className="px-10 py-4 rounded-full btn-secondary flex items-center"
            >
             Our Plans
            </a>
          </div>
        </DigitalAnimation>

        <DigitalAnimation>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-16 text-center mt-10">
            <div className="max-w-xs m-auto">
              <div className="text-center">
                <span className="h-20 w-20 inline-block bg-white rounded-full ">
                <LottoIcon type='Carousel-Vertical_lottie' className="text-red"></LottoIcon>
                </span>
              

              </div>
              <div className="text-2xl font-semibold mt-3 md:mt-6">
              Unlimited Design Board
              </div>
              <p className="mt-3 text-black mb-4 max-w-[276px]">
              Choose Any Of The Plan Which Suits You The Best.
              </p>
            </div>

            <div className="max-w-xs m-auto">
            <div className="text-center">
                <span className="h-20 w-20 inline-block bg-white rounded-full ">
                <LottoIcon type='clock' className="text-red"></LottoIcon>
                </span>
              

              </div>
              <div className="text-2xl font-semibold mt-3 md:mt-6">
              24-48 Hours Turnaround
              </div>
              <p className="mt-3 mb-4  max-w-[270px]">
              Put All The Task On Trello, Slack Or Loom Video. 
              </p>
            </div>

            <div className="max-w-xs m-auto">
            <div className="text-center">
                <span className="h-20 w-20 inline-block bg-white rounded-full ">
                <LottoIcon type='Calender-Checked_lottie' className="text-red"></LottoIcon>
                </span>
              

              </div>
            <div className="text-2xl font-semibold mt-3 md:mt-6">
            Fixed Monthly Rate
              </div>
              <p className="mt-3 mb-4 max-w-[270px]">
              Get You Design Updates In Just 24-48 Hours Turnaround
              </p>
            </div>

            <div className="max-w-xs m-auto">
            <div className="text-center">
                <span className="h-20 w-20 inline-block bg-white rounded-full ">
                <LottoIcon type='Bin-Throw_lottie' className="text-red"></LottoIcon>
                </span>
              

              </div>
              <div className="text-2xl font-semibold mt-3 md:mt-6">
              Unlimited Revision
              </div>
              <p className="mt-3 mb-4 max-w-[276px]">
              Choose Any Of The Plan Which Suits You The Best.
              </p>
            </div>

            <div className="max-w-xs m-auto">
              <div className="text-center">
                <span className="h-20 w-20 inline-block bg-white rounded-full ">
                <LottoIcon type='Binocular_lottie' className="text-red"></LottoIcon>
                </span>
              </div>
              <div className="text-2xl font-semibold mt-3 md:mt-6">
              Dedicate Designer
              </div>
              <p className="mt-3 mb-4 max-w-[270px]">
              Put All The Task On Trello, Slack Or Loom Video.
              </p>
            </div>

            <div className="max-w-xs m-auto">
            <div className="text-center">
                <span className="h-20 w-20 inline-block bg-white rounded-full ">
                <LottoIcon type='Bookmark_lottie' className="text-red"></LottoIcon>
                </span>
              </div>
              <div className="text-2xl font-semibold mt-3 md:mt-6">
              Flexible And Scalable
              </div>
              <p className="mt-3 mb-4 max-w-[276px]">
              Empower Your Growth With Our Flexibly And Scalable Solutions
              </p>
            </div>
          </div>
        </DigitalAnimation>

      </div>
    </section>

    {/* <section className="news py-16 md:py-28">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 text-left">
            <div className="mb-4">
              <a href="#" className="block">
                <img src={slider2} className="w-full"></img>
              </a>
              <div className="grid grid-cols-2 gap-8 pt-6">
                <p>Amazon</p>
                <p className="text-right">Finance</p>
              </div>
              <div className="mt-2 text-2xl font-medium">
                <a href="#">
                  How Youtube found freedom, Flexibility, and Success.
                </a>
              </div>
            </div>
            <div className="mb-4">
              <a href="#" className="block">
                <img src={slider2} className="w-full"></img>
              </a>
              <div className="grid grid-cols-2 gap-8 pt-6">
                <p>Amazon</p>
                <p className="text-right">Finance</p>
              </div>
              <div className="mt-2 text-2xl font-medium">
                <a href="#">
                  How Youtube found freedom, Flexibility, and Success.
                </a>
              </div>
            </div>
            <div className="mb-4">
              <a href="#" className="block">
                <img src={slider2} className="w-full"></img>
              </a>
              <div className="grid grid-cols-2 gap-8 pt-6">
                <p>Amazon</p>
                <p className="text-right">Finance</p>
              </div>
              <div className="mt-2 text-2xl font-medium">
                <a href="#">
                  How Youtube found freedom, Flexibility, and Success.
                </a>
              </div>
            </div>
            <div className="mb-4">
              <a href="#" className="block">
                <img src={slider2} className="w-full"></img>
              </a>
              <div className="grid grid-cols-2 gap-8 pt-6">
                <p>Amazon</p>
                <p className="text-right">Finance</p>
              </div>
              <div className="mt-2 text-2xl font-medium">
                <a href="#">
                  How Youtube found freedom, Flexibility, and Success.
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}

    {/* <section className="services py-16 py-28 bg-black hidden">
        <div className="container mx-auto px-4">
          <div className="text-white uppercase font-medium mb-4">
            What we do
          </div>
          <h2 className="text-5xl font-bold text-white">
            Apps, websites, logos & more.
          </h2>

          <div className="max-w-screen-lg mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-18 lg:gap-x-24 lg:gap-y-18 mt-20 text-left">
              <div>
                <div className="text-white text-2xl text-white font-medium">
                  Websites Design
                </div>
                <div className="text-gray-400 mt-4">
                  Our design agency in Paris worked on the Axelhire presentation
                  for their Series C round of fundraising and guess what!
                </div>
              </div>

              <div>
                <div className="text-white text-2xl text-white font-medium">
                  Logos & branding
                </div>
                <div className="text-gray-400 mt-4">
                  Our design agency in Paris worked on the Axelhire presentation
                  for their Series C round of fundraising and guess what!
                </div>
              </div>

              <div>
                <div className="text-white text-2xl text-white font-medium">
                  Mobile apps
                </div>
                <div className="text-gray-400 mt-4">
                  Our design agency in Paris worked on the Axelhire presentation
                  for their Series C round of fundraising and guess what!
                </div>
              </div>

              <div>
                <div className="text-white text-2xl text-white font-medium">
                  Design systems
                </div>
                <div className="text-gray-400 mt-4">
                  Our design agency in Paris worked on the Axelhire presentation
                  for their Series C round of fundraising and guess what!
                </div>
              </div>

              <div>
                <div className="text-white text-2xl text-white font-medium">
                  Illustration
                </div>
                <div className="text-gray-400 mt-4">
                  Our design agency in Paris worked on the Axelhire presentation
                  for their Series C round of fundraising and guess what!
                </div>
              </div>

              <div>
                <div className="text-white text-2xl text-white font-medium">
                  Brandbook
                </div>
                <div className="text-gray-400 mt-4">
                  Our design agency in Paris worked on the Axelhire presentation
                  for their Series C round of fundraising and guess what!
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
  </div>
}
import { FooterComponent } from "./components/footer";
import { HeaderComponent } from "./components/header";
import { DigitalAnimation } from "./components/animation/digitalAnimation";
export function PrivacyPolicy(){
    return <div>


       <HeaderComponent />
       <DigitalAnimation>
       
    <div className="container mx-auto px-4 py-12 md:py-20 ">
    <div className="text-center mt-12"><span class="hero-badge">Last updated: 05/23/2023</span></div>
      <h1 className="text-center">
      Privacy Policy
      </h1>
      
    </div>
    </DigitalAnimation>
       <div class="container mx-auto px-4 pb-24">
        <p className="mt-6">
        At Dstudiotech, we value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you subscribe to our design services. By using our services, you consent to the practices described in this policy.        </p>
        {/* <p className="mt-6">
        THEREFORE, this Privacy Policy Agreement shall apply to Uidino Agency , and thus it shall govern any and all data collection and usage thereof. Through the use of https://Whale UI.digital you are herein consenting to the following data procedures expressed within this agreement.
        </p> */}

        
        
        <h2 className="mt-6 font-bold h4">
        1. Information We Collect
        </h2>
        <p className="mt-6">
        We may collect various types of information to provide and improve our design subscription services:
        </p>
        <ul className="list-disc list-inside mt-6">
            <li>
            Personal Information: When you subscribe to our services, we may collect your name, email address, billing information, and contact details.
            </li>
            <li>
            Usage Information: We may collect information about how you interact with our website and services, including your browsing activity, the pages you visit, and the features you use.

            </li>
            <li>
            Communications: If you contact us directly, we may keep records of our communications, including emails and messages.

            </li>
        </ul>

        <h2 className="mt-6 font-bold h4">
        2. How We Use Your Information
        </h2>
        <p className="mt-6">
        We use the collected information for various purposes, including:
        </p>
       <ul className="list-disc list-inside mt-6">
        <li>
        Providing Services: To deliver design services according to your subscription plan.
        </li>
        <li>
        Communication: To communicate with you about your subscription, updates, and any inquiries you make.

        </li>
        <li>
        Improvement: To analyze usage patterns and enhance our services, website, and user experience.

        </li>
        <li>
        Marketing: To send promotional materials, newsletters, and special offers if you've opted to receive them.
        </li>
       </ul>
        <h2 className="mt-6 font-bold h4">
        3. Information Sharing and Disclosure
        </h2>
        <p className="mt-6">
        We respect your privacy and do not sell, trade, or rent your personal information to third parties. However, we may share your information in the following circumstances:
        </p>
        <ul className="list-disc list-inside mt-6">
          <li>
          Service Providers: We may share information with third-party service providers who help us deliver our services, process payments, and manage customer support.

          </li>
          <li>
          Legal Compliance: We may disclose your information to comply with legal obligations, enforce our policies, or respond to legal requests.

          </li>
        </ul>
        <h2 className="mt-6 font-bold h4">
        4. Data Security
        </h2>
        <p className="mt-6">
        We prioritize the security of your data and implement appropriate measures to protect it. However, no data transmission over the internet is entirely secure. We cannot guarantee the security of information you transmit to us.
        </p>
        <h2 className="mt-6 font-bold h4">
        5. Your Choices
        </h2>
        <p className="mt-6">
        You can control how we use your data:
        </p>
        <ul className="list-disc list-inside mt-6">
          <li>
          Marketing Communications: You can choose to opt out of receiving marketing emails from us by using the "unsubscribe" link provided in the emails.
          </li>
          <li>
          Updating Information: You can update or correct your personal information by contacting us.

          </li>
        </ul>

        <h2 className="mt-6 font-bold h4">
        6. Cookies and Tracking
        </h2>
        <p className="mt-6">
        We use cookies and similar technologies to collect information about your interactions with our website. You can manage your cookie preferences through your browser settings.
        </p>
        <h2 className="mt-6 font-bold h4">
        7. Changes to Privacy Policy
        </h2>
        <p className="mt-6">
        We may update our Privacy Policy to reflect changes in our practices. We will notify you of any substantial changes through our website or by email.
        </p>

        <h2 className="mt-6 font-bold h4">
        8. Contact Us
        </h2>
        <p className="mt-6">
        If you have questions about our Privacy Policy or how we handle your information, please contact us at hello@dstudio.agency
        </p>

        <p className="mt-6">
        By subscribing to our design services, you acknowledge and consent to the terms outlined in this Privacy Policy.
        </p>

        </div>

        <FooterComponent></FooterComponent>
    </div>
}
export const slideIn = {
    offscreen: { y: 100, opacity: 0 },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: { type: "tween"},
      // transition: { duration: 1 },
      // transition: { type: "spring", stiffness: 90 },
    },
  };
  
import { Link } from "react-router-dom";
import React from 'react';
import { DigitalAnimation } from "./animation/digitalAnimation";
import fb from '../assets/brand/facebook.svg';
// import clutch from '../assets/brand/clutch.svg';
import dribbble from '../assets/brand/dribble.svg';
import insta from '../assets/brand/insta.svg';
import linkedin from '../assets/brand/linkedin.svg';
import arrow from "../assets/arrow.svg"
import { SubscribeForm } from "./subscribe";

export function FooterComponent() {
  return (
    //   <footer className="pt-16 bg-black text-white pb-6">
    //     <div className="grid grid-cols-2 ">
    //       <div>

    //       </div>
    //     </div>
    //   <div className="container mx-auto px-4 text-left">
    //     <div className="text-normal md:text-lg font-bold pb-10">
    //       Let’s Create Something Better Together
    //     </div>

    //     <h3 className="text-xl md:text-5xl font-bold mb-2">Write us on</h3>
    //     <h4 className="text-4xl md:text-8xl font-bold">
    //       <a href="mailto:hello@Vivi.digital">hello@Vivi.digital</a>
    //     </h4>
    //     <div className="pt-4 inline-block mt-14">
    //       <a
    //         href="#"
    //         className="px-10 py-4 rounded-full bg-white hover:bg-green-300 hover:shadow-lg text-black flex items-center font-semibold"
    //       >
    //         Get Quote{" "}
    //         <svg
    //           className="ms-2 animate-bounce"
    //           width="14"
    //           height="14"
    //           viewBox="0 0 14 14"
    //           fill="none"
    //           xmlns="http://www.w3.org/2000/svg"
    //         >
    //           <path
    //             d="M7 1.1665V12.8332"
    //             stroke="black"
    //             stroke-width="2"
    //             stroke-linecap="round"
    //             stroke-linejoin="round"
    //           ></path>
    //           <path
    //             d="M12.8333 7L7.00001 12.8333L1.16667 7"
    //             stroke="black"
    //             stroke-width="2"
    //             stroke-linecap="round"
    //             stroke-linejoin="round"
    //           ></path>
    //         </svg>
    //       </a>
    //     </div>
    //     <div>
    //       <hr className="my-16 border-bottom border-gray-800"></hr>
    //     </div>

    //     <div className="grid grid-col-1 md:grid-cols-3 gap-8  lg:gap-16">
    //       <div className=" max-w-lg">
    //         <div className="footer-logo">
    //           <img src={logoW}></img>
    //         </div>

    //         <div className="text-3xl font-bold mt-6">5.0</div>
    //         <div className="font-medium mt-2">Rated on Clutch</div>
    //       </div>
    //       <div>
    //         <div className="font-semibold text-2xl">Location</div>
    //         <div className="font-bold text-lg mt-6">India</div>
    //         <p className="mt-3">Gurugram, Badha Sec - 86 122004 , Haryana</p>

    //         <div className="font-bold text-lg mt-10">United States</div>
    //         <p className=" mt-3">
    //           585 West 500 south, Suite 130 Bountiful, Salt Lake City, Utah
    //           84010
    //         </p>
    //       </div>
    //       <div>
    //         <div className="font-semibold text-2xl">Social</div>
    //         <ul className="mt-6">
    //           <li>
    //             <a href="#" className="py-1 hover:text-green-300 block">
    //               Dribbble
    //             </a>
    //           </li>
    //           <li>
    //             <a href="#" className="py-1 hover:text-green-300 block">
    //               Instagram
    //             </a>
    //           </li>
    //           <li>
    //             <a href="#" className="py-1 hover:text-green-300 block">
    //               Behance
    //             </a>
    //           </li>
    //           <li>
    //             <a href="#" className="py-1 hover:text-green-300 block">
    //               Clutch
    //             </a>
    //           </li>
    //         </ul>
    //       </div>
    //     </div>

    //     <div className="grid grid-cols-1 md:grid-cols-2 mt-12 md:mt-20 gap-4 lg:gap-10">
    //       <div className="text-sm">
    //         <Link to="/about" className="me-7 hover:text-green-400">
    //           About Us
    //         </Link>
    //         <Link to="/contact" className="me-7  hover:text-green-400">
    //           Contact
    //         </Link>
    //         <Link to="/terms-and-condition" className="me-7  hover:text-green-400">
    //           Terms and Condition
    //         </Link>
    //       </div>
    //       <div className="text-sm text-left md:text-right">
    //         c 2023 Whale Ui - All rights Reserved
    //       </div>
    //     </div>
    //   </div>
    // </footer>


    <footer className="bg-white pt-24 pb-20">
      <div className="container mx-auto px-4">
        <DigitalAnimation>

          <div className="grid grid-cols-1 gap-4">
            {/* First column content */}
            <div className=" mx-auto text-center">
              <div className="font-medium mb-0 footer-heading tracking-tight">Dstudio_tech</div>
              <p className="hero-badge">
              get pixel perfect design ~ code
              </p>
              <div className="text-center">
                <div className="devider inline-block"></div>
              </div>
              <div className="text-center">
                <SubscribeForm/>
              </div>
              <div className="text-center mt-10 font-medium">
              Remote Team Globally 👨‍💻            
              </div>

            </div>
            {/* Second column content */}
            <div>

              <div className="flex text-left pt-12 md:pt-4 hidden">
                {/* First column */}
                <div className="flex-initial pe-8">
                  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="28" cy="28" r="27.5" stroke="#E5E5E5" />
                    <line x1="16.2929" y1="38.2929" x2="27.2929" y2="27.2929" stroke="black" stroke-width="2" />
                  </svg>

                </div>

                {/* Second column */}
                <div className="flex-auto">
                  <div className="text-sm font-medium">India</div>
                  <div className="text-[#363636] text-sm max-w-sm uppercase mt-2">
                    Gurugram, Badha Sec - 86 122004 ,<br></br>
                    Haryana
                  </div>
                </div>
              </div>

              <div className="flex text-left pt-10 hidden">
                {/* First column */}
                <div className="flex-initial pe-0">
                  {/* <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="28" cy="28" r="27.5" stroke="#E5E5E5" />
                    <line x1="16.2929" y1="38.2929" x2="27.2929" y2="27.2929" stroke="black" stroke-width="2" />
                  </svg> */}
                  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z" fill="white" />
                    <path d="M43.094 36.2726C43.039 36.2726 42.984 36.2636 42.929 36.2446L40.707 35.4716C40.446 35.3806 40.309 35.0956 40.399 34.8346C40.489 34.5736 40.775 34.4366 41.036 34.5266L43.258 35.2996C43.519 35.3906 43.656 35.6756 43.566 35.9366C43.494 36.1436 43.301 36.2726 43.094 36.2726Z" fill="#101820" />
                    <path d="M44.799 32.4996C44.717 32.4996 44.635 32.4796 44.558 32.4376C44.316 32.3046 44.228 32.0006 44.361 31.7586L46.221 28.3776L41.276 27.5536L39.894 30.2296C39.767 30.4746 39.466 30.5696 39.22 30.4446C38.975 30.3176 38.879 30.0156 39.005 29.7706L40.555 26.7706C40.655 26.5776 40.868 26.4716 41.081 26.5066L47.081 27.5066C47.241 27.5336 47.378 27.6356 47.449 27.7816C47.52 27.9276 47.515 28.0986 47.437 28.2406L45.237 32.2406C45.147 32.4056 44.976 32.4996 44.799 32.4996Z" fill="#101820" />
                    <path d="M23 11.5005C22.724 11.5005 22.5 11.2765 22.5 11.0005C22.5 9.22648 20.739 7.37448 20 6.67448C19.261 7.37448 17.5 9.22648 17.5 11.0005C17.5 11.2765 17.276 11.5005 17 11.5005C16.724 11.5005 16.5 11.2765 16.5 11.0005C16.5 8.29748 19.55 5.72448 19.68 5.61648C19.866 5.46248 20.135 5.46248 20.321 5.61648C20.45 5.72448 23.5 8.29748 23.5 11.0005C23.5 11.2765 23.276 11.5005 23 11.5005Z" fill="#101820" />
                    <path d="M18 17.5C17.776 17.5 17.572 17.349 17.516 17.121L16.516 13.121C16.478 12.972 16.512 12.813 16.607 12.692C16.701 12.571 16.846 12.5 17 12.5H20C21.378 12.5 22.5 13.622 22.5 15C22.5 16.378 21.378 17.5 20 17.5C19.724 17.5 19.5 17.276 19.5 17C19.5 16.724 19.724 16.5 20 16.5C20.827 16.5 21.5 15.827 21.5 15C21.5 14.173 20.827 13.5 20 13.5H17.641L18.485 16.879C18.552 17.147 18.389 17.418 18.121 17.485C18.081 17.495 18.04 17.5 18 17.5Z" fill="#101820" />
                    <path d="M24 13.5H16C15.724 13.5 15.5 13.276 15.5 13C15.5 12.724 15.724 12.5 16 12.5H24C24.276 12.5 24.5 12.724 24.5 13C24.5 13.276 24.276 13.5 24 13.5Z" fill="#101820" />
                    <path d="M21 25.5002C20.76 25.5002 20.548 25.3273 20.507 25.0823L19.533 19.2402L17.646 17.3533C17.451 17.1583 17.451 16.8412 17.646 16.6462C17.841 16.4513 18.158 16.4513 18.353 16.6462L20.353 18.6463C20.427 18.7203 20.476 18.8142 20.493 18.9172L21.493 24.9172C21.538 25.1892 21.354 25.4473 21.082 25.4923C21.054 25.4983 21.027 25.5002 21 25.5002Z" fill="#101820" />
                    <path d="M23.333 23.1666C23.116 23.1666 22.916 23.0236 22.853 22.8046L22.376 21.1376C22.3 20.8716 22.454 20.5956 22.719 20.5186C22.985 20.4476 23.261 20.5966 23.338 20.8616L23.815 22.5286C23.891 22.7946 23.737 23.0706 23.472 23.1476C23.425 23.1596 23.379 23.1666 23.333 23.1666Z" fill="#101820" />
                    <path d="M22.856 21.5C22.639 21.5 22.439 21.357 22.376 21.137L21.519 18.137C21.469 17.963 21.518 17.775 21.646 17.646L23.5 15.792V15C23.5 14.724 23.724 14.5 24 14.5C24.276 14.5 24.5 14.724 24.5 15V16C24.5 16.133 24.447 16.26 24.354 16.354L22.562 18.146L23.338 20.863C23.414 21.129 23.26 21.405 22.994 21.481C22.948 21.494 22.902 21.5 22.856 21.5Z" fill="#101820" />
                    <path d="M20 26.5H19C18.724 26.5 18.5 26.276 18.5 26V23C18.5 22.867 18.553 22.74 18.646 22.646L20.075 21.217C20.27 21.022 20.587 21.022 20.782 21.217C20.977 21.412 20.977 21.729 20.782 21.924L19.5 23.207V25.5H19.793L23.5 21.793V21.5H23C22.724 21.5 22.5 21.276 22.5 21C22.5 20.724 22.724 20.5 23 20.5H24C24.276 20.5 24.5 20.724 24.5 21V22C24.5 22.133 24.447 22.26 24.354 22.354L20.354 26.354C20.26 26.447 20.133 26.5 20 26.5Z" fill="#101820" />
                    <path d="M21 28.5H20C19.867 28.5 19.74 28.447 19.646 28.354L18.646 27.354C18.553 27.26 18.5 27.133 18.5 27V26C18.5 25.724 18.724 25.5 19 25.5C19.276 25.5 19.5 25.724 19.5 26V26.793L20.207 27.5H21C21.276 27.5 21.5 27.724 21.5 28C21.5 28.276 21.276 28.5 21 28.5Z" fill="#101820" />
                    <path d="M24.7499 24.4996C24.7099 24.4996 24.6689 24.4946 24.6279 24.4846C24.3599 24.4176 24.1969 24.1466 24.2639 23.8786L24.4689 23.0576L23.5519 21.2236C23.4279 20.9766 23.5289 20.6766 23.7759 20.5526C24.0229 20.4296 24.3229 20.5296 24.4469 20.7766L25.4469 22.7766C25.4999 22.8836 25.5139 23.0056 25.4849 23.1216L25.2349 24.1216C25.1779 24.3486 24.9749 24.4996 24.7499 24.4996Z" fill="#101820" />
                    <path d="M38 17.5C37.87 17.5 37.742 17.449 37.646 17.354C34.793 14.5 33.622 14.5 32 14.5C30.378 14.5 29.207 14.5 26.354 17.354C26.211 17.498 25.996 17.54 25.809 17.462C25.622 17.385 25.5 17.202 25.5 17V15C25.5 14.867 25.553 14.74 25.646 14.646C28.793 11.5 30.279 11.5 32 11.5C33.721 11.5 35.207 11.5 38.354 14.646C38.447 14.74 38.5 14.867 38.5 15V17C38.5 17.202 38.378 17.385 38.191 17.462C38.129 17.488 38.064 17.5 38 17.5ZM32 13.5C33.557 13.5 34.923 13.5 37.5 15.832V15.208C34.752 12.5 33.593 12.5 32 12.5C30.407 12.5 29.248 12.5 26.5 15.208V15.832C29.077 13.5 30.443 13.5 32 13.5Z" fill="#101820" />
                    <path d="M33 12.5H31C30.853 12.5 30.713 12.436 30.619 12.323C30.524 12.211 30.483 12.063 30.507 11.918L31.507 5.918C31.547 5.677 31.755 5.5 32 5.5C32.245 5.5 32.453 5.677 32.493 5.918L33.493 11.918C33.517 12.063 33.476 12.211 33.381 12.323C33.287 12.436 33.147 12.5 33 12.5ZM31.59 11.5H32.409L32 9.042L31.59 11.5Z" fill="#101820" />
                    <path d="M37.0001 14.4996C36.9231 14.4996 36.8471 14.4816 36.7761 14.4466L36.6251 14.3706C36.5881 14.3516 36.5531 14.3286 36.5211 14.3016C35.9441 13.8026 35.4331 13.4276 34.9591 13.1556C34.8341 13.0846 34.7471 12.9636 34.7181 12.8226C34.6891 12.6816 34.7221 12.5356 34.8081 12.4216L37.6001 8.69958C37.7401 8.51158 37.9911 8.44758 38.2041 8.54258C38.4171 8.63758 38.5361 8.86858 38.4901 9.09758L37.4901 14.0976C37.4601 14.2506 37.3601 14.3806 37.2191 14.4486C37.1501 14.4826 37.0751 14.4996 37.0001 14.4996ZM35.9351 12.5866C36.1701 12.7436 36.4131 12.9216 36.6651 13.1226L37.0771 11.0636L35.9351 12.5866Z" fill="#101820" />
                    <path d="M27 14.4996C26.925 14.4996 26.85 14.4826 26.78 14.4486C26.64 14.3806 26.54 14.2506 26.509 14.0976L25.509 9.09758C25.463 8.86858 25.582 8.63858 25.795 8.54258C26.008 8.44758 26.259 8.51158 26.4 8.69958L29.192 12.4226C29.278 12.5366 29.311 12.6826 29.282 12.8236C29.253 12.9636 29.165 13.0856 29.041 13.1566C28.566 13.4286 28.056 13.8036 27.478 14.3026C27.446 14.3296 27.412 14.3526 27.374 14.3716L27.223 14.4476C27.153 14.4816 27.077 14.4996 27 14.4996ZM26.922 11.0626L27.334 13.1216C27.587 12.9216 27.83 12.7436 28.065 12.5856L26.922 11.0626Z" fill="#101820" />
                    <path d="M26 20.4999H25C24.724 20.4999 24.5 20.2759 24.5 19.9999C24.5 19.7239 24.724 19.4999 25 19.4999H26C29.635 19.4999 31.534 15.8129 31.553 15.7759C31.677 15.5289 31.977 15.4289 32.224 15.5539C32.471 15.6779 32.571 15.9769 32.447 16.2239C32.36 16.3979 30.26 20.4999 26 20.4999Z" fill="#101820" />
                    <path d="M32 24.5001C29.519 24.5001 27.5 22.4811 27.5 20.0001V19.6431C27.5 19.4341 27.631 19.2461 27.828 19.1741C30.268 18.2781 31.541 15.8001 31.554 15.7761C31.626 15.6331 31.763 15.5331 31.921 15.5071C32.078 15.4821 32.241 15.5341 32.354 15.6461C32.372 15.6651 34.234 17.5001 36 17.5001C36.276 17.5001 36.5 17.7241 36.5 18.0001V20.0001C36.5 22.4811 34.481 24.5001 32 24.5001ZM28.5 19.9821V20.0001C28.5 21.9301 30.07 23.5001 32 23.5001C33.93 23.5001 35.5 21.9301 35.5 20.0001V18.4651C34.095 18.2751 32.794 17.3411 32.122 16.7801C31.586 17.6151 30.388 19.1701 28.5 19.9821Z" fill="#101820" />
                    <path d="M28 24.5C27.872 24.5 27.744 24.451 27.646 24.354C25.524 22.231 25.5 17.212 25.5 17C25.5 16.724 25.724 16.5 26 16.5C26.276 16.5 26.5 16.724 26.5 17C26.5 17.048 26.524 21.817 28.354 23.646C28.549 23.841 28.549 24.158 28.354 24.353C28.256 24.451 28.128 24.5 28 24.5Z" fill="#101820" />
                    <path d="M36 24.4999C35.872 24.4999 35.744 24.4509 35.646 24.3539C35.451 24.1589 35.451 23.8419 35.646 23.6469C36.292 23.0009 36.785 21.9129 37.114 20.4099C37.173 20.1389 37.437 19.9679 37.709 20.0289C37.979 20.0879 38.149 20.3539 38.09 20.624C37.72 22.316 37.135 23.5719 36.352 24.3539C36.256 24.4509 36.128 24.4999 36 24.4999Z" fill="#101820" />
                    <path d="M37.872 19.393C37.852 19.393 37.833 19.392 37.813 19.39C37.539 19.358 37.343 19.109 37.375 18.835C37.498 17.79 37.5 17.008 37.5 17C37.5 16.724 37.724 16.5 38 16.5C38.276 16.5 38.5 16.724 38.5 17C38.5 17.034 38.499 17.844 38.368 18.951C38.338 19.206 38.122 19.393 37.872 19.393Z" fill="#101820" />
                    <path d="M36.5 21.5H35.858C35.703 21.5 35.557 21.428 35.462 21.306C35.367 21.184 35.335 21.024 35.374 20.874C35.46 20.545 35.5 20.267 35.5 20V18C35.5 17.724 35.724 17.5 36 17.5H36.5C37.603 17.5 38.5 18.397 38.5 19.5C38.5 20.603 37.603 21.5 36.5 21.5ZM36.467 20.5H36.5C37.051 20.5 37.5 20.051 37.5 19.5C37.5 18.949 37.051 18.5 36.5 18.5V20C36.5 20.164 36.489 20.329 36.467 20.5Z" fill="#101820" />
                    <path d="M32 27.5003C30.622 27.5003 29.5 26.3783 29.5 25.0003V23.4453C29.5 23.2663 29.596 23.1013 29.751 23.0113C29.907 22.9223 30.098 22.9233 30.251 23.0123C31.36 23.6583 32.639 23.6583 33.748 23.0123C33.902 22.9223 34.093 22.9213 34.248 23.0113C34.403 23.1013 34.499 23.2653 34.499 23.4453V25.0003C34.499 25.2763 34.275 25.5003 33.999 25.5003C33.723 25.5003 33.499 25.2763 33.499 25.0003V24.2323C32.53 24.5873 31.468 24.5873 30.499 24.2323V25.0003C30.499 25.8273 31.172 26.5003 31.999 26.5003C32.103 26.5003 32.205 26.4893 32.302 26.4693C32.577 26.4193 32.837 26.5903 32.892 26.8603C32.947 27.1313 32.772 27.3953 32.501 27.4503C32.34 27.4823 32.172 27.5003 32 27.5003Z" fill="#101820" />
                    <path d="M25 23.4995C24.813 23.4995 24.634 23.3945 24.548 23.2145C24.429 22.9645 24.535 22.6665 24.785 22.5475L26.648 21.6605C26.898 21.5425 27.195 21.6485 27.315 21.8975C27.434 22.1475 27.328 22.4455 27.078 22.5645L25.215 23.4515C25.146 23.4835 25.072 23.4995 25 23.4995Z" fill="#101820" />
                    <path d="M23.849 34.0762C23.643 34.0762 23.451 33.9482 23.378 33.7432L22.4629 31.1672C22.3709 30.9072 22.506 30.6212 22.767 30.5282C23.025 30.4352 23.313 30.5722 23.406 30.8322L24.3209 33.4082C24.4129 33.6682 24.278 33.9542 24.017 34.0472C23.961 34.0662 23.905 34.0762 23.849 34.0762Z" fill="#101820" />
                    <path d="M46 36.5H44C42.622 36.5 41.5 35.378 41.5 34C41.5 32.622 42.622 31.5 44 31.5H46C46.276 31.5 46.5 31.724 46.5 32V36C46.5 36.276 46.276 36.5 46 36.5ZM44 32.5C43.173 32.5 42.5 33.173 42.5 34C42.5 34.827 43.173 35.5 44 35.5H45.5V32.5H44Z" fill="#101820" />
                    <path d="M22 37.4996C21.949 37.4996 21.898 37.4916 21.849 37.4766C21.72 37.4356 21.613 37.3446 21.553 37.2236L20.553 35.2236C20.473 35.0626 20.485 34.8716 20.584 34.7226L21.917 32.7226C22.07 32.4936 22.379 32.4306 22.61 32.5836C22.84 32.7366 22.902 33.0476 22.749 33.2766L21.577 35.0356L22.217 36.3146L34.624 29.6336L36.553 25.7756C36.677 25.5286 36.976 25.4286 37.224 25.5516C37.471 25.6756 37.571 25.9756 37.448 26.2226L35.448 30.2226C35.402 30.3146 35.328 30.3906 35.238 30.4396L22.238 37.4396C22.164 37.4796 22.082 37.4996 22 37.4996Z" fill="#101820" />
                    <path d="M33 29.5002C32.872 29.5002 32.744 29.4512 32.646 29.3542C32.451 29.1592 32.451 28.8422 32.646 28.6472L33.646 27.6472C33.841 27.4522 34.158 27.4522 34.353 27.6472C34.548 27.8422 34.548 28.1592 34.353 28.3542L33.353 29.3542C33.256 29.4512 33.128 29.5002 33 29.5002Z" fill="#101820" />
                    <path d="M23 34.4996H21.667C21.391 34.4996 21.167 34.2756 21.167 33.9996C21.167 33.7236 21.391 33.4996 21.667 33.4996H22.882L24.777 32.5526C25.024 32.4296 25.323 32.5296 25.448 32.7766C25.572 33.0236 25.471 33.3236 25.224 33.4476L23.224 34.4476C23.154 34.4816 23.078 34.4996 23 34.4996Z" fill="#101820" />
                    <path d="M20.9999 30.4997C20.9619 30.4997 20.9229 30.4957 20.8849 30.4867C20.7399 30.4527 20.6189 30.3567 20.5519 30.2237L19.5519 28.2237C19.4279 27.9767 19.5289 27.6767 19.7759 27.5527C20.0239 27.4287 20.3229 27.5297 20.4469 27.7767L21.1789 29.2407L24.6309 26.6517L25.9169 24.7227C26.0699 24.4937 26.3789 24.4307 26.6099 24.5837C26.8399 24.7367 26.9019 25.0477 26.7489 25.2767L25.4159 27.2767C25.3849 27.3237 25.3449 27.3657 25.2999 27.3997L21.2999 30.3997C21.2119 30.4647 21.1069 30.4997 20.9999 30.4997Z" fill="#101820" />
                    <path d="M24 41.5005C23.87 41.5005 23.742 41.4495 23.646 41.3545L22.646 40.3545C22.553 40.2605 22.5 40.1335 22.5 40.0005V36.4615C22.5 36.1855 22.724 35.9615 23 35.9615C23.276 35.9615 23.5 36.1855 23.5 36.4615V39.7935L24.114 40.4075L30.714 37.5785L34.031 34.2625C34.226 34.0675 34.543 34.0675 34.738 34.2625C34.933 34.4575 34.933 34.7745 34.738 34.9695L31.353 38.3545C31.308 38.3995 31.255 38.4355 31.196 38.4605L24.196 41.4605C24.133 41.4875 24.066 41.5005 24 41.5005Z" fill="#101820" />
                    <path d="M24.9999 38.5002C24.8159 38.5002 24.6399 38.3992 24.5519 38.2242C24.4279 37.9772 24.5289 37.6772 24.7759 37.5532L28.7759 35.5532L32.6999 32.6002C32.9209 32.4342 33.2339 32.4792 33.3999 32.7002C33.5659 32.9212 33.5209 33.2342 33.2999 33.4002L29.2999 36.4002L25.2239 38.4472C25.1519 38.4832 25.0759 38.5002 24.9999 38.5002Z" fill="#101820" />
                    <path d="M40 39.5002C39.87 39.5002 39.742 39.4492 39.646 39.3542L38.535 38.2432C38.34 38.0482 38.34 37.7312 38.535 37.5362C38.73 37.3412 39.047 37.3412 39.242 37.5362L40.135 38.4292L42.73 37.5642L44.647 35.6472C44.842 35.4522 45.159 35.4522 45.354 35.6472C45.549 35.8422 45.549 36.1592 45.354 36.3542L43.354 38.3542C43.299 38.4092 43.232 38.4502 43.159 38.4752L40.159 39.4752C40.106 39.4922 40.053 39.5002 40 39.5002Z" fill="#101820" />
                    <path d="M41 37.5H38.8C38.524 37.5 38.3 37.276 38.3 37C38.3 36.724 38.524 36.5 38.8 36.5H41C41.276 36.5 41.5 36.724 41.5 37C41.5 37.276 41.276 37.5 41 37.5Z" fill="#101820" />
                    <path d="M26 46.5002C25.953 46.5002 25.906 46.4942 25.86 46.4802C25.726 46.4412 25.615 46.3482 25.552 46.2242L24.552 44.2242C24.518 44.1542 24.5 44.0782 24.5 44.0002V40.5712C24.5 40.2952 24.724 40.0712 25 40.0712C25.276 40.0712 25.5 40.2952 25.5 40.5712V43.8822L26.206 45.2942L30.67 42.6152L33.314 39.4422C33.491 39.2302 33.806 39.2022 34.019 39.3782C34.231 39.5552 34.26 39.8702 34.083 40.0832L31.385 43.3212C31.349 43.3642 31.306 43.4012 31.258 43.4292L26.258 46.4292C26.179 46.4762 26.09 46.5002 26 46.5002Z" fill="#101820" />
                    <path d="M26.9999 42.5002C26.8159 42.5002 26.6399 42.3992 26.5519 42.2242C26.4279 41.9772 26.5289 41.6772 26.7759 41.5532L30.7759 39.5532C31.0239 39.4292 31.3229 39.5292 31.4469 39.7772C31.5709 40.0242 31.4699 40.3242 31.2229 40.4482L27.2229 42.4482C27.1519 42.4832 27.0759 42.5002 26.9999 42.5002Z" fill="#101820" />
                    <path d="M37 46.5005C36.959 46.5005 36.919 46.4955 36.879 46.4855L32.879 45.4855C32.632 45.4235 32.471 45.1865 32.504 44.9345L34.504 29.9345C34.541 29.6605 34.792 29.4685 35.066 29.5045C35.34 29.5415 35.532 29.7925 35.496 30.0665L33.555 44.6235L36.847 45.4465L37.647 44.6465C37.74 44.5535 37.867 44.5005 38 44.5005H38.5V39.0005L37.508 29.1055L36.605 26.3955L34.173 25.5845L30.391 30.3125C30.33 30.3875 30.25 30.4435 30.159 30.4745L25.159 32.1415C24.896 32.2295 24.614 32.0875 24.527 31.8255C24.44 31.5635 24.581 31.2805 24.843 31.1935L29.703 29.5735L33.611 24.6885C33.742 24.5255 33.96 24.4605 34.16 24.5265L37.16 25.5265C37.309 25.5755 37.427 25.6935 37.476 25.8425L38.476 28.8425C38.488 28.8775 38.496 28.9145 38.499 28.9505L39.499 38.9505L39.5 45.0005C39.5 45.2765 39.276 45.5005 39 45.5005H38.207L37.353 46.3545C37.259 46.4485 37.131 46.5005 37 46.5005Z" fill="#101820" />
                    <path d="M38 45.5001C37.816 45.5001 37.64 45.3991 37.552 45.2241C37.21 44.5381 35.5 41.0641 35.5 40.0001C35.5 39.5291 35.698 37.8241 36.088 34.9331C36.126 34.6601 36.378 34.4691 36.651 34.5041C36.925 34.5411 37.116 34.7931 37.08 35.0671C36.575 38.8001 36.5 39.7601 36.5 40.0001C36.5 40.6081 37.639 43.1591 38.447 44.7761C38.571 45.0231 38.47 45.3231 38.223 45.4471C38.152 45.4831 38.076 45.5001 38 45.5001Z" fill="#101820" />
                    <path d="M30.5 46.5001C30.473 46.5001 30.445 46.4981 30.417 46.4931C30.145 46.4481 29.96 46.1901 30.006 45.9181L30.506 42.9181C30.551 42.6461 30.809 42.4571 31.081 42.5071C31.353 42.5521 31.538 42.8101 31.492 43.0821L30.992 46.0821C30.953 46.3271 30.741 46.5001 30.5 46.5001Z" fill="#101820" />
                    <path d="M45 54.5C44.724 54.5 44.5 54.276 44.5 54V50.5H19.5V54C19.5 54.276 19.276 54.5 19 54.5C18.724 54.5 18.5 54.276 18.5 54V50C18.5 49.724 18.724 49.5 19 49.5H45C45.276 49.5 45.5 49.724 45.5 50V54C45.5 54.276 45.276 54.5 45 54.5Z" fill="#101820" />
                    <path d="M43 50.5H21C20.724 50.5 20.5 50.276 20.5 50V48C20.5 47.724 20.724 47.5 21 47.5H43C43.276 47.5 43.5 47.724 43.5 48V50C43.5 50.276 43.276 50.5 43 50.5ZM21.5 49.5H42.5V48.5H21.5V49.5Z" fill="#101820" />
                    <path d="M39 46.5C38.724 46.5 38.5 46.276 38.5 46V45C38.5 44.724 38.724 44.5 39 44.5C39.276 44.5 39.5 44.724 39.5 45V46C39.5 46.276 39.276 46.5 39 46.5Z" fill="#101820" />
                    <path d="M44 30.2501C43.96 30.2501 43.9189 30.2451 43.8779 30.2351L41.8779 29.7351C41.6099 29.6681 41.447 29.3971 41.514 29.1291C41.581 28.8611 41.8499 28.6951 42.1199 28.7651L44.1199 29.2651C44.3879 29.3321 44.551 29.6031 44.484 29.8711C44.428 30.0991 44.225 30.2501 44 30.2501Z" fill="#101820" />
                    <path d="M48.268 16.5C47.992 16.5 47.768 16.276 47.768 16C47.768 15.173 47.095 14.5 46.268 14.5C45.734 14.5 45.236 14.787 44.969 15.25C44.831 15.488 44.525 15.57 44.286 15.432C44.047 15.294 43.965 14.988 44.104 14.749C44.549 13.978 45.379 13.5 46.269 13.5C47.647 13.5 48.769 14.622 48.769 16C48.768 16.276 48.544 16.5 48.268 16.5Z" fill="#101820" />
                    <path d="M48.2679 16.5C47.9919 16.5 47.7679 16.276 47.7679 16C47.7679 14.622 48.8899 13.5 50.2679 13.5C51.1579 13.5 51.9879 13.979 52.4329 14.749C52.5719 14.988 52.4899 15.294 52.2509 15.432C52.0109 15.57 51.7059 15.488 51.5679 15.25C51.2999 14.787 50.8019 14.5 50.2679 14.5C49.4409 14.5 48.7679 15.173 48.7679 16C48.7679 16.276 48.5439 16.5 48.2679 16.5Z" fill="#101820" />
                    <path d="M49.2679 16.5H47.2679C46.9919 16.5 46.7679 16.276 46.7679 16C46.7679 15.724 46.9919 15.5 47.2679 15.5H49.2679C49.5439 15.5 49.7679 15.724 49.7679 16C49.7679 16.276 49.5439 16.5 49.2679 16.5Z" fill="#101820" />
                    <path d="M18 36.5H13C12.724 36.5 12.5 36.276 12.5 36C12.5 35.724 12.724 35.5 13 35.5H18C18.276 35.5 18.5 35.724 18.5 36C18.5 36.276 18.276 36.5 18 36.5Z" fill="#101820" />
                    <path d="M51 43.5H49C48.724 43.5 48.5 43.276 48.5 43C48.5 42.724 48.724 42.5 49 42.5H51C51.276 42.5 51.5 42.724 51.5 43C51.5 43.276 51.276 43.5 51 43.5Z" fill="#101820" />
                    <path d="M26.9999 29.1674C26.7909 29.1674 26.5959 29.0354 26.5259 28.8254C26.4389 28.5634 26.5799 28.2804 26.8419 28.1934L28.8419 27.5264C29.1079 27.4374 29.3869 27.5814 29.4739 27.8424C29.5609 28.1044 29.4199 28.3874 29.1579 28.4744L27.1579 29.1414C27.1049 29.1584 27.0519 29.1674 26.9999 29.1674Z" fill="#101820" />
                    <path d="M18 47.9679C17.932 47.9679 17.862 47.9539 17.795 47.9239C16.635 47.4019 14.891 46.8979 13.159 47.4749C9.99997 48.5299 7.86697 47.4929 7.77697 47.4479C7.52997 47.3239 7.42997 47.0239 7.55297 46.7769C7.67797 46.5299 7.97697 46.4309 8.22397 46.5529C8.23997 46.5609 10.098 47.4419 12.842 46.5259C14.879 45.8459 16.884 46.4149 18.206 47.0119C18.458 47.1249 18.57 47.4219 18.456 47.6729C18.372 47.8579 18.19 47.9679 18 47.9679Z" fill="#101820" />
                    <path d="M53.612 49.3839C52.787 49.3839 51.809 49.1399 50.723 48.4159C48.966 47.2449 47.217 47.1619 46.059 47.2969C45.781 47.3259 45.536 47.1329 45.504 46.8589C45.472 46.5849 45.668 46.3359 45.942 46.3039C47.27 46.1479 49.269 46.2439 51.278 47.5839C53.906 49.3349 55.63 47.6639 55.647 47.6469C55.842 47.4519 56.159 47.4519 56.354 47.6469C56.549 47.8419 56.549 48.1589 56.354 48.3539C56.338 48.3689 55.294 49.3839 53.612 49.3839Z" fill="#101820" />
                    <path d="M52 52.8343C51.967 52.8343 51.934 52.8313 51.901 52.8243C51.491 52.7413 51.113 52.6153 50.776 52.4473C49.71 51.9143 48.639 51.6823 47.929 51.5813C47.656 51.5423 47.466 51.2893 47.504 51.0163C47.544 50.7433 47.8 50.5553 48.069 50.5913C48.853 50.7023 50.036 50.9593 51.222 51.5523C51.479 51.6803 51.774 51.7793 52.097 51.8443C52.368 51.8983 52.543 52.1623 52.489 52.4333C52.442 52.6703 52.234 52.8343 52 52.8343Z" fill="#101820" />
                    <path d="M16 52.086C15.944 52.086 15.887 52.077 15.832 52.057C15.122 51.803 14.055 51.5 13 51.5C12.724 51.5 12.5 51.276 12.5 51C12.5 50.724 12.724 50.5 13 50.5C14.199 50.5 15.385 50.835 16.168 51.116C16.428 51.209 16.564 51.495 16.47 51.755C16.397 51.959 16.205 52.086 16 52.086Z" fill="#101820" />
                    <path d="M41 52.5H24C23.724 52.5 23.5 52.276 23.5 52C23.5 51.724 23.724 51.5 24 51.5H41C41.276 51.5 41.5 51.724 41.5 52C41.5 52.276 41.276 52.5 41 52.5Z" fill="#101820" />
                  </svg>


                </div>

                {/* Second column */}
                <div className="flex-auto">
                  <div className="text-sm font-medium">United States</div>
                  <div className="text-[#363636] text-sm max-w-sm uppercase mt-2">
                    585 West 500 south, Suite 130 Bountiful, Salt Lake City, Utah 84010
                  </div>
                </div>
              </div>

            </div>
          </div>
        </DigitalAnimation>
        <DigitalAnimation>


          <div className="social pt-16 text-center">
          <a href="https://dribbble.com/dstudiotech" target={"_blank"} className="group me-4 md:me-16 inline-block">
              <img src={dribbble} alt="dribble" className="h-11" />
            </a>

            <a href="https://www.instagram.com/dstudio_tech/" target={"_blank"} className="group me-4 md:me-16 inline-block">
              <img src={insta} alt="instagram" className="h-11" />
            </a>

            {/* <a href="https://www.facebook.com/uidino" target={"_blank"} className="group me-4 md:me-16 inline-block">
              <img src={fb} alt="facebook" className="h-11" />
            </a>
             */}

            <a href="https://www.linkedin.com/company/dstudiotech/" target={"_blank"} className="group me-4 md:me-0 inline-block">
              <img src={linkedin} alt="linkedin" className="h-11" />
            </a>
            
          </div>
        </DigitalAnimation>
        <DigitalAnimation>


          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 mt-12 items-center">
            <div className="text-center md:text-left">
              <div className="footer-email">
              <div className="inline-block me-2" onClick={() => window.scrollTo(0, 0)}>
                <img src={arrow} className="h-8 md:h-16"></img>
              </div>
              <a href="mailto:hello@dstudio.agency" className="inline-block">hello@dstudio.agency</a>
              </div>
              
            </div>
            <div className="text-center md:text-center text-sm">
              {/* <div className="inline-block">
            powered by - Design Pixelone Pvt. Ltd           
              </div> */}
             </div>
            <div className="text-center md:text-right text-sm" onClick={() => window.scrollTo(0, 0)}>
              <Link to="/terms-and-condition" className="pe-4 md:pe-8 hover:text-dark">Terms & conditions</Link>
              <Link to="/privacy-policy" className="pe-4 md:pe-8 hover:text-dark">Privacy Policy</Link>
            </div>
          </div>

        </DigitalAnimation>
      </div>
    </footer>
  )
}
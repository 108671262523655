
import { FooterComponent } from "./components/footer";
import { HeaderComponent } from "./components/header";
import { DigitalAnimation } from "./components/animation/digitalAnimation";
import { Link } from "react-router-dom";
import { Clients } from "./components/clients";
export function AboutPage(){
    return <div>
    <HeaderComponent />

    <DigitalAnimation>
    <div className="container mx-auto px-4 py-16 md:py-28">
      <h1 className="text-5xl md:text-7xl font-bold max-w-2xl">
      About Us
      </h1>
      <div className="flex justify-start lg:justify-end">
        <div className="max-w-xl mt-4">
          <p className="text-xl heading-text">
          A Group of UX Architects, Creative Designers, and Problem Solvers
Around the globe
          </p>
          <div className="pt-4 inline-block">
            <Link
              to="/#price"
              className="px-6 sm:px-10 py-4 rounded-full bg-black hover:bg-gray-900 hover:shadow-lg text-white flex items-center font-semibold"
            >
              See Plans{" "}
              <svg
                className="ms-2 animate-bounce"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 1.1665V12.8332"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M12.8333 7L7.00001 12.8333L1.16667 7"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Link>
          </div>
          <div className="pt-4 inline-block ms-2">
            <a
              href="mailto:sales@whaleui.agency"
              className="flex px-6 sm:px-8 py-4 rounded-full bg-white hover:bg-gray-200 text-black flex items-center font-semibold"
            >
              <span>Contact Sales</span>
              <svg
                className="ms-2 animate-bounce"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.83334 10.1668L10.1667 1.8335"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1.83334 1.8335H10.1667V10.1668"
                  stroke="black"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
    </DigitalAnimation>
     <section className="">
     <DigitalAnimation>
        <div className="container mx-auto px-4 pt-12 pb-16">
        <div className="">
            <div className="text-left">
            

            </div>
            <div>
            <div className="grid grid-cols-1 text-4xl md:text-5xl font-bold max-w-4xl">
                <div>
                <h2 className="text-4xl md:text-5xl font-bold max-w-4xl">
            A group of design experts who believes in the transformative power of creativity and innovation.
            </h2>

            <h3 className="mt-8">
                    We are a team of 15 people, and help to craft the solution using our skills, we are capable of doing
                    </h3>
                    <h3 className="mt-8">
                    Webdesign, branding, mobile apps, dashboard, pitch deck, graphic, motion and many more.
        </h3>

        <h4 className="mt-8">

        Our team worked for many startups and Fortune 500 companies including deloitte, Mckinsey.
        Kwik and rated 5.0.
        </h4>
                </div>
            </div>
            </div>
        </div>



        </div>
    </DigitalAnimation>
     </section>
    <div>
    <Clients></Clients>
     </div>
     <FooterComponent></FooterComponent>
 </div>
}
import React, { useState } from 'react';
import { PlusIcon } from '@heroicons/react/24/solid'
import { AnimatePresence, motion } from 'framer-motion'

export function Accordion({ title, content }) {
  const [expanded, setExpanded] = useState(false)
  const toggleExpanded = () => setExpanded((current) => !current)


  const renderContent=(content)=>{
    if(Array.isArray(content)){
      return <ol className='list-decimal list-inside'>{content.map(m=><li className='mb-4'>{m}</li>)}</ol>
    }else{
      return content
    }
  }
  return (
    <div className={" mb-1.5 sm:my-4 md:my-4 border-opacity-75 cursor-pointer border-2 border-[#E7E8EC] bg-white group rounded-xl " + (expanded ? "bg-[#FCFCFD]" : "")} onClick={toggleExpanded}>
      <div className="px-6 pt-6 text-left select-none flex justify-between flex-row">
        <p className="flex-1 text-xl pb-6">
          {title}
        </p>

        
        <div className="flex-none pl-2 pt-0"> <PlusIcon className={'h-6 w-6 font-bold '+(expanded?'transition delay-250 duration-30 rotate-45':'transition delay-250 duration-30 rotate-90')} /></div>
      </div>
      <AnimatePresence initial={false}>
        {expanded && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 }
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
            className='px-6 pt-0  overflow-y-hidden		'
            
          >
            <motion.p className="text-left pb-6 ">
            {renderContent(content)}
            </motion.p>
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  )
}
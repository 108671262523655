import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { slideIn } from "./animation";
import { Link } from "react-router-dom";

export default function CaseStudyCard({ post }) {
  const [hoverImage, setHoverImage] = useState(0);
  const [isHovering, setIsHovered] = useState(false);
  const onMouseEnter = () => {
    setIsHovered(true);
    setHoverImage(0);
  };
  const onMouseLeave = () => setIsHovered(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setHoverImage(hoverImage < 2 ? hoverImage + 1 : 0);
    }, 1500);

    return () => {
      clearTimeout(timeout);
    };
  }, [hoverImage]);
  return (
    <article
      key={post.id}
      className="flex flex-col items-start justify-between font-sans"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className="relative w-full">
        <Link href={`/case-study/${post.id}`}>
          {isHovering ? (
            <motion.img
              src={post.urls[hoverImage]}
              alt=""
              className="w-full bg-gray-100 object-cover cursor-pointer rounded-xl md:rounded-2xl"
              variants={slideIn}
              initial={"offscreen"}
              whileInView={"onscreen"}
            />
          ) : (
            <motion.img
              src={post.urls[0]}
              alt=""
              className="w-full bg-gray-100 object-cover cursor-pointer rounded-xl md:rounded-2xl"
              variants={slideIn}
              initial={"offscreen"}
              whileInView={"onscreen"}
            />
          )}
        </Link>
      </div>
      <div className="w-full">
        <motion.div
          className="relative"
          variants={slideIn}
          initial={"offscreen"}
          whileInView={"onscreen"}
        >
{/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4 content-center uppercase pt-6">
<h3 className="font-sans text-2xl font-semibold leading-8 text-black max-w-xl">
            {post.title}
          </h3>
          <p className="text-black md:text-right mt-1">{post.client}</p>
</div> */}

          
          {/* <Link href={`/case-study/${post.id}`}>
            <p className="group mt-5 text-xl leading-6 text-black line-clamp-3 flex font-medium">
              View case study &nbsp;
              <span className="text-base">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6 transition-all duration-300 ease-in-out group-hover:ml-2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                  />
                </svg>
              </span>
            </p>
          </Link> */}
        </motion.div>
      </div>
    </article>
  );
}

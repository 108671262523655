import { useState } from "react"
import { makePostRequest } from "../lib/axios.helper";


export function SubscribeForm() {
    const [email, setEmail] = useState()
    const [validEmail, setValidEmail] = useState(true)
    const [success, setSuccess] = useState(false)

    const submitForm = async () => {
        let re = /\S+@\S+\.\S+/;
        debugger
        if (re.test(email)) {
            console.log(email)
            let payload = {
                email,
                description: "want to know about plans and coupons"
            }
            let res = await makePostRequest(payload)
            if(res.status==200){
                console.log(res)
                setSuccess(true)
                setEmail('')
                setTimeout(() => {
                    setSuccess(false)
                }, 3000);
            }

        } else {
            setValidEmail(false)
        }
    }
    return <div className="footer-subscribe">
        <input type="email" placeholder="Enter your email" value={email} onChange={(e) => {
            setEmail(e.target.value);
            setValidEmail(true)
        }}></input>
        <button type="submit" placeholder="Your Email" onClick={submitForm}>Submit</button>
        {!validEmail && <p className="text-red-600">Please enter a valid email id</p>}
        {success && <p className="text-green-600">Email Subscribed Succssfully!</p>}

    </div>
}
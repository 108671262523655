// import Lottie from 'react-lottie';

// export const LottoIcon = (props) => {
//     let animationData
   
//     const defaultOptions = {
//         loop: props.loop||true,
//         autoplay: props.autoplay||true,
//         animationData: require(`../../assets/lottie/${props.type}.json`),
//         rendererSettings: {
//             preserveAspectRatio: 'xMidYMid slice'
//         }
//     }
//     return <Lottie options={defaultOptions}
//         height={props.height}
//         width={props.width}
//     />
// }

import React from "react";
import { useLottie } from "lottie-react";


export function LottoIcon(props){
    const options = {
      animationData: require(`../../assets/lottie/${props.type}.json`),
      loop: true
    };
  
    const { View } = useLottie(options);
    return <div style={{width:"100%",height:"100%"}}>{View}</div>
  }
// import React, { useState } from 'react';
import { Accordion } from './helper/accordian';
import { DigitalAnimation } from './animation/digitalAnimation';

export function Faq() {
    const questions = [

        {
            title: " What precisely is the definition of “Unlimited Design”?",
            content: "After selecting your plan, you’re free to include an unlimited number of design tasks/requests in your queue. You can prioritize them based on importance, and we’ll address them sequentially. Once a task is approved, we’ll proceed to the next one in line."
        },
        {
            title: "What is the turnaround time for the completion of the designs?",
            content: "* Basic requests like marketing assets will typically be ready for your review within a few business days (Monday to Friday). However, more intricate tasks such as website or app designs may require additional time. In such cases, we’ll strive to break down the project into smaller deliverables based on the nature of the request. Rest assured, we’ll provide regular updates on the progress to keep you informed of what to expect."
        },
        { title: "Who is the designer I will be working with?", content: "Excellent inquiry! What sets us apart from other subscription agencies is our commitment to a dedicated, in-house team. We don’t rely on freelancers or outsourcing. only the most talented individuals contribute to creating stunning designs. Furthermore, we implement a thorough quality check before delivering any work to our clients." },
        { title: " Is it possible to pause my subscription?", content: "Certainly! Feel free to suspend your subscription at any point for the upcoming month, and when you’re ready to resume, you can easily reactivate it." },
        { title: " Are there any items or services that are not covered in the packages?", content: "We’re equipped to handle everything outlined in the Plan Benefits section of the website. However, it’s important to note that our current services do not encompass video creation/production, animation/motion design, 3D rendering/visualizations, or development/writing code. Should you need assistance with any of these areas, we’d be more than happy to provide guidance on whom to contact for these specific services." },
        
        { title: "In which design software do you typically create your designs?", content: 'Our primary design tool is Figma. if necessary, we are flexible and can utilize additional tools like the Adobe Creative Suite based on your specific requirements.' },
        { title: "Is there a limit to how many requests I can have?", content:"Once subscribed, you're able to add as many design requests to your queue as you'd like, and they will be delivered one by one"},
        { title: "What will my trial period look like?", content: "During the trial period, we focus on delivering an exceptional result for a single task to showcase our capabilities and encourage you to consider a subscription. Please be aware that, due to the constrained timeframe of the trial, we do not offer revisions. Our goal is to provide the best possible outcome for a task that is achievable within a 5-day period, with 5 hours of work each day." },
        { title: "Tell us about Frontend - CMS Plan.", content: "Our team specializes in offering comprehensive frontend solutions tailored to your needs. Simply outline your project requirements, and our dedicated development team will assess the scope of work. Following this, we’ll promptly send you an email detailing the timeline and pricing for your consideration." },
        {title:"Are there any refunds if I don't like the service?", content:"Due to the high quality nature of the work, there will be no refunds issued at any case."}
    ]
    return (
        <section className='py-16 md:pt-10 md:pb-28' id="faq">
            <div className='container mx-auto px-4'>
                <DigitalAnimation>
                    {/* <div className="text-black uppercase font-medium mb-4">
                        FAQ’s
                    </div> */}
                    <span className='hero-badge mb-6'>
                    Question in Mind?
                    </span>

                    <h2 className="text-5xl md:text-6xl text-black pb-12">
                        Frequently Asked <br></br>Questions
                    </h2>
                </DigitalAnimation>
                <div className='max-w-4xl mx-auto'>
                    {questions.map(q => <DigitalAnimation> <Accordion title={q.title} content={q.content} /> </DigitalAnimation>)}
                </div>
            </div>

        </section>
    )
}
import React from 'react';
import { DigitalAnimation } from './animation/digitalAnimation';
import { Clients } from "../components/clients";
import { HeroProducts } from '../heroProducts';

export function HeroComponent() {
  return <section className="text-center pt-20">
    <DigitalAnimation>
      <div className='container mx-auto px-4'>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4 text-left mt-10 lg:mt-[100px]'>
                <div>
                <h1 className='max-w-[346px]'>Get Unlimited Design</h1>
                </div>
                <div className='h-100 flex items-end md:justify-end'>
                    <div className='flex w-auto gap-8 justify-between items-center auto-rows-max'>
                      <div className='separator text-3xl hidden md:block'><svg width="72" height="72" className='animate-spin' viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2692_2297)">
<path d="M35.6201 29.601V1.5" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M35.6201 70.7397V42.6387" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M42.1387 36.1191H70.2397" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M1 36.1191H29.101" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M40.2285 31.5116L60.0995 11.6406" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M11.1396 60.6015L31.0106 40.7305" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M40.2285 40.7305L60.0995 60.6015" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M11.1396 11.6406L31.0106 31.5116" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round"/>
</g>
<defs>
<clipPath id="clip0_2692_2297">
<rect width="71.24" height="71.24" fill="white" transform="translate(0 0.5)"/>
</clipPath>
</defs>
</svg>
</div>
                      <div className='display1 max-w-[210px] text-lg'>Design Subscriptions For — Everyone. ` Pause Cancel Anytime.</div>
                      <div>
                      <a
              href="https://dribbble.com/dstudiotech" target="_blank"
              className="px-4 sm:px-6 py-3 btn btn-sm btn-secondary w-auto custom-btn"
            >
             Work
             <svg 
              height="512px"
              version="1.1"
              viewBox="0 0 512 512"
              width="512px">
                <path fill='#fff' d="M322.7,128.4L423,233.4c6,5.8,9,13.7,9,22.4c0,8.7-3,16.5-9,22.4L322.7,383.6c-11.9,12.5-31.3,12.5-43.2,0  c-11.9-12.5-11.9-32.7,0-45.2l48.2-50.4h-217C93.7,288,80,273.7,80,256c0-17.7,13.7-32,30.6-32h217l-48.2-50.4  c-11.9-12.5-11.9-32.7,0-45.2C291.4,115.9,310.7,115.9,322.7,128.4z"/>
              </svg>
            </a>
                      </div>
                    </div>
                </div>
          </div>
      </div>
      <HeroProducts></HeroProducts>
      <Clients></Clients>
    <div className="container mx-auto mt-24 px-4">
        {/* <div>
        <span className='hero-badge'>100% Premium Design Services</span>
        </div> */}
      <h1 className='text-center max-w-[945px] m-auto'>
      Get Monthly Subscription Solution For Branding ~ UI/UX - Design And Development.
      {/* <span className='text-blue-700'>Products.</span> */}
      </h1>
      <div>
        <div className="container mx-auto pt-2 relative px-4">
          
          <div className='text-center mt-10'>
              <div className='flex justify-start items-center flex-col'>
              <div className='px-8 pr-12 separator text-xl flex items-center hidden md:flex mb-8'>
              <svg width="55" height="56" viewBox="0 0 55 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M23.8736 39.2718L24.4134 24.4281L11.8636 32.3897L8.625 26.7221L21.8494 19.84L8.625 12.9579L11.8636 7.29031L24.4134 15.252L23.8736 0.408203H30.3509L29.8111 15.252L42.3608 7.29031L45.5994 12.9579L32.375 19.84L45.5994 26.7221L42.3608 32.3897L29.8111 24.4281L30.3509 39.2718H23.8736Z" fill="#292421"/>
<path d="M0.366104 54.5561C0.328462 52.4733 0.667241 50.7982 1.38244 49.5309C2.09764 48.2636 3.02614 47.3414 4.16795 46.7642C5.32231 46.187 6.5394 45.8984 7.81923 45.8984C9.09906 45.8984 10.266 46.1808 11.3199 46.7454C12.3865 47.2975 13.6036 48.1695 14.9712 49.3615C15.8997 50.1645 16.64 50.7292 17.1921 51.0554C17.7567 51.3816 18.3715 51.5447 19.0366 51.5447C20.1156 51.5447 20.9689 51.1558 21.5962 50.3778C22.2236 49.5874 22.5247 48.4455 22.4996 46.9524H26.565C26.6026 49.0353 26.2638 50.7103 25.5486 51.9776C24.846 53.2449 23.9175 54.1671 22.7631 54.7443C21.6088 55.3215 20.3917 55.6101 19.1118 55.6101C17.832 55.6101 16.6651 55.334 15.6111 54.782C14.5572 54.2173 13.3401 53.339 11.9599 52.147C11.0439 51.344 10.3036 50.7794 9.73897 50.4531C9.17434 50.1269 8.55952 49.9638 7.89451 49.9638C6.87818 49.9638 6.03751 50.3214 5.3725 51.0366C4.72003 51.7392 4.40635 52.9124 4.43145 54.5561H0.366104Z" fill="#292421"/>
<path d="M28.789 47.2134C28.7513 49.2963 29.0901 50.9714 29.8053 52.2386C30.5205 53.5059 31.449 54.4281 32.5908 55.0053C33.7452 55.5825 34.9623 55.8711 36.2421 55.8711C37.5219 55.8711 38.6888 55.5888 39.7428 55.0241C40.8093 54.4721 42.0264 53.6 43.3941 52.408C44.3226 51.605 45.0629 51.0404 45.615 50.7141C46.1796 50.3879 46.7944 50.2248 47.4594 50.2248C48.5385 50.2248 49.3917 50.6138 50.0191 51.3917C50.6464 52.1822 50.9476 53.324 50.9225 54.8171H54.9878C55.0255 52.7343 54.6867 51.0592 53.9715 49.7919C53.2688 48.5246 52.3403 47.6024 51.186 47.0252C50.0316 46.448 48.8145 46.1594 47.5347 46.1594C46.2549 46.1594 45.088 46.4355 44.034 46.9876C42.98 47.5522 41.7629 48.4305 40.3827 49.6225C39.4667 50.4255 38.7265 50.9902 38.1618 51.3164C37.5972 51.6426 36.9824 51.8058 36.3174 51.8058C35.301 51.8058 34.4604 51.4482 33.7953 50.733C33.1429 50.0303 32.8292 48.8571 32.8543 47.2134H28.789Z" fill="#292421"/>
</svg>

              </div>
                <div className='clutch-user flex justify-center items-center md:justify-start'>
                    <ul>
                      <li className='client1'></li>
                      <li className='client2'></li>
                      <li className='client3'></li>
                      <li className='client4'></li>
                    </ul>
                </div>
                
                <div className='flex justify-center items-center mt-5 md:mt-6'>
                 <div className='text-center max-w-[514px]'>
                  <div className='text-[24px]'>“Dstudio Agency completed what we asked of them, and the results looked great!</div>
                  <div className='font-bold mt-3'>Carson- CTO, @Sundays</div>
                 </div>
                </div>
              </div>
          </div>
          {/* <div className="pt-4 inline-block ms-2">
            <a
              href="mailto:sales@.agency"
              className="flex px-6 sm:px-8 py-4 btn btn-outline-light btn-lg"
            >
             Our Pricing
            </a>
          </div> */}

        </div>
      </div>

          {/* <div className='hero-circle hero-circle__1'>
              f
          </div>
          <div className='hero-circle hero-circle__2'>
              a
          </div>
          <div className='hero-circle hero-circle__3'>
              f
          </div>
          <div className='hero-circle hero-circle__4'>
              a
          </div>
          <div className='hero-circle hero-circle__5'>
              f
          </div>
          <div className='hero-circle hero-circle__6'>
              a
          </div>
          <div className='hero-circle hero-circle__7'>
              a
          </div>
          <div className='hero-circle hero-circle__8'>
              a
          </div> */}
    </div>
    </DigitalAnimation>
    
  </section>
}
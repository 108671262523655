import { motion } from 'framer-motion'

export function DigitalAnimation(props) {
    const variants = {
        hidden: {
            y: 30,
            opacity: 0
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: 1.2, delay: 0.1 }
        }
    }
    return <motion.div
        variants={variants}
        initial={'hidden'}
        whileInView={'visible'}
        viewport={{once:true}}
    >

        {props.children}

    </motion.div>
} 
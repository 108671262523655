import React from 'react';
import { DigitalAnimation } from './animation/digitalAnimation';

export function WorkComponent() {
  return <section className="text-center pt-32">
    <DigitalAnimation>
      <div className='container mx-auto px-4'>
        <span className='hero-badge mb-6'>Our Services</span>
        <h2 className='text-5xl md:text-6xl font-bold text-black mb-4'>We Cover <br/>Design ~ Code</h2>
        <div className='pt-4'>
          <a href='' className='px-6 sm:px-10 py-4 btn btn-secondary inline-block'>View Work</a>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-4 text-center mt-10'>
          <div className='bx-container px-3 border-[#E7E8ED] border relative hover:border-[black] ease-in duration-75'>
            <div className='text-2xl mt-8 font-semibold'>Website</div>
            <p className='mt-6 text-black mb-8 max-w-[235px] mx-auto'>Captivating web experiences for your business’s digital presence.</p>
          </div>
          <div className='bx-container px-3 border-[#E7E8ED] border relative hover:border-[black] ease-in duration-75'>
            <div className='text-2xl mt-8 font-semibold'>Mobile App</div>
            <p className='mt-6 text-black mb-8 max-w-[235px] mx-auto'>Revolutionize user experiences with our cutting-edge App Design.</p>
          </div>
          <div className='bx-container px-3 border-[#E7E8ED] border relative hover:border-[black] ease-in duration-75'>
            <div className='text-2xl mt-8 font-semibold'>Branding</div>
            <p className='mt-6 text-black mb-8 max-w-[235px] mx-auto'>Elevate your brand identity with our transformative tech branding.</p>
          </div>
          <div className='bx-container px-3 border-[#E7E8ED] border relative hover:border-[black] ease-in duration-75'>
            <div className='text-2xl mt-8 font-semibold'>UI/UX</div>
            <p className='mt-6 text-black mb-8 max-w-[235px] mx-auto'>We offer Webflow, Framer, Wix, Html CSS / Java and Wordpress</p>
          </div>
          <div className='bx-container px-3 border-[#E7E8ED] border relative hover:border-[black] ease-in duration-75'>
            <div className='text-2xl mt-8 font-semibold'>Pitch Deck</div>
            <p className='mt-6 text-black mb-8 max-w-[235px] mx-auto'>Make an unforgettable impression with our impactful tech pitch decks.</p>
          </div>
          <div className='bx-container px-3 border-[#E7E8ED] border relative hover:border-[black] ease-in duration-75'>
            <div className='text-2xl mt-8 font-semibold'>Social Media</div>
            <p className='mt-6 text-black mb-8 max-w-[235px] mx-auto'>Ignite your online presence with engaging and creative social posts.</p>
          </div>
          <div className='bx-container px-3 border-[#E7E8ED] border relative hover:border-[black] ease-in duration-75'>
            <div className='text-2xl mt-8 font-semibold'>Product Design</div>
            <p className='mt-6 text-black mb-8 max-w-[235px] mx-auto'>Streamline data insights with our intuitive and innovative dashboards.</p>
          </div>
          <div className='bx-container px-3 border-[#E7E8ED] border relative hover:border-[black] ease-in duration-75'>
            <div className='text-2xl mt-8 font-semibold'>Frontend Dev</div>
            <p className='mt-6 text-black mb-8 max-w-[235px] mx-auto'>We offer Webflow, Framer, Wix, Html CSS / Java and Wordpress</p>
          </div>
        </div>
      </div>
    </DigitalAnimation>
    
  </section>
}
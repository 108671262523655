import React from 'react';
import { CONTACT_EMAIL } from '../constants';
export const getMailLink=(params)=>{
  return `mailto:${CONTACT_EMAIL}?subject=Interested in ${params.name} plan &body= Hi, I would like to start with ${params.amount}. Please provide more information regarding the plan's details, terms, and timeline.`
}

export function Plans({ params }) {
 
  return params.recommended ? <div className="group p-8 border bg-[#000] border-[#E7E8ED] hover:border-[#000] relative text-white most-popular-bg rounded-md">
    <div className="most-popular hidden">
      Most Popular
    </div>
    <div className="text-xl font-semibold">{params?.name}</div>
    <p className="mt-2 text-base opacity-60">
      {params?.text1}
    </p>
    <div className='my-6 border-bottom-d border-[#8E8E8E]'></div>
    <div className="text-[34px] tracking-tighter font-bold mt-8 text-dark">
      <sup></sup>{params?.amount}
    </div>
    <p className="mt-2 text-sm text-[#8E8E8E]">{params.text2}</p>
    <a
    // style={{pointerEvents:"none"}}
      href={params.disabled?getMailLink(params):params?.paymentLink}
      className="mt-8 max-w-[208px] mx-auto text-white bg-[var(--black)] border border-[var(--white)] btn-group rounded-full inline-block md:block text-center px-6 py-3 font-medium"
    >
           {params?.button}

    </a>
    <a href='https://calendly.com/meetings-dstudio/15min' target='_blank' className='text-[#8E8E8E] hover:no-underline underline mt-6 block text-center'>Schedule A Call</a>
    <div>
      {/* <div className="text-lg font-medium mt-8 mb-4">
        {params?.text3}
      </div> */}
      <ul className="price-list-ul text-gray-700 mt-8 text-sm">
        {params?.features?.map(f => <li>{f}</li>)}
      </ul>
    </div>
    <div className='mt-8 services-border'>
      <div className='text-lg	fw-bold'>Service Included 👇</div>
      <ul className='flex service-included'>
        {
          params?.services.map(m=><li>{m}</li>)
        }
      </ul>
    </div>
  </div> : <div className="group rounded-md bg-white p-8 ease-in duration-75 border border-[#E7E8ED] hover:border-[#000] price-monthly text-black">
    <div className="text-xl font-semibold flex justify-between ">
      <span>{params?.name}</span>
       {params?.popular && <span className='bg-black rounded-3xl text-white text-sm	pl-2 pr-2 py-1 '>Popular</span> }
    </div>
    <p className="mt-2 text-base opacity-60">
      {params?.text1}
    </p>
    <div className='my-6 border-bottom-d'></div>

    <div className="text-[34px] tracking-tighter font-bold mt-8 text-dark">{params?.amount}</div>
    <p className="mt-2 text-sm min-h-[40px]">{params?.text2}</p>
    {params?.text2.length<55 && <div className='h-[0]'></div>}


    <a
      href={params.disabled?getMailLink(params):params?.paymentLink}
      className="mt-8 mx-auto transition-all ease-in-out ease-in duration-75 border border-[var(--secondary)] rounded-full bg-white hover:bg-[var(--secondary)] hover:text-white inline-block md:block text-center px-6 py-3 font-medium max-w-[208px] btn-group"
    >
      {params?.button}
    </a>
    <a href='https://calendly.com/meetings-dstudio/15min' target='_blank' className='text-[#1A1A1A] hover:no-underline underline mt-6 block text-center'>Schedule A Call</a>
    <div>
      {/* <div className="text-lg font-medium mt-8 mb-4">
        {params?.text3}
      </div> */}
      <ul className="price-list-ul text-gray-700 mt-8 text-sm">

        {params?.features?.map(f => <li>{f}</li>)}
      </ul>
    </div>

    <div className='mt-8 services-border'>
      <div className='text-lg	fw-bold'>Service Included 👇</div>
      <ul className='flex service-included'>
      {
          params?.services.map(m=><li>{m}</li>)
        }
      </ul>
    </div>
  </div>
}
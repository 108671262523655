import { DigitalAnimation } from "./animation/digitalAnimation";
import CaseStudyCard from "./helper/caseStudyCard";
import React from 'react';

export function CaseStudy() {
    const studies = [
        {
            id: 5,
            title: "Brand Guide",
            href: "#",
            description:
                "Project manager",
            imageUrl: require('../assets/case-study-brand/Brand.png'),
            urls: [
                require('../assets/case-study-brand/Brand.png'),
                require('../assets/case-study-brand/Brand.png'),
                require('../assets/case-study-brand/Brand.png')
            ],
            client: "Web App",
        },
        {
            id: 5,
            title: "Website",
            href: "#",
            description:
                "Project manager",
            imageUrl: require('../assets/case-study-website/website.png'),
            urls: [
                require('../assets/case-study-website/website.png'),
                require('../assets/case-study-website/website.png'),
                require('../assets/case-study-website/website.png')
            ],
            client: "Web App",
        },
        {
            id: 1,
            title: "Task Manager",
            href: "#",
            description:
                "Project manager",
            imageUrl: require('../assets/case-study-1/CS-1-a.png'),
            urls: [
                require('../assets/case-study-1/CS-1-a.png'),
                require('../assets/case-study-1/CS-1-a.png'),
                require('../assets/case-study-1/CS-1-a.png')
            ],
            client: "Web App",
        },
        {
            id: 2,
            title: "Agency Website",
            href: "#",
            description:
                "Get set up, issue cards, and start making payments in less than 15 minutes-whether you have 5 employees or 5,000",
            imageUrl: require('../assets/case-study-2/cs-2-a.png')
            ,
            urls: [
                require('../assets/case-study-2/cs-2-a.png'),
                require('../assets/case-study-2/cs-2-a.png'),
                require('../assets/case-study-2/cs-2-a.png')],
            client: "Mobile Ui",
        },
        {
            id: 3,
            title: "Calendar App",
            href: "#",
            description:
                "Get set up, issue cards, and start making payments in less than 15 minutes-whether you have 5 employees or 5,000",
            imageUrl: require('../assets/case-study-3/cs-3-a.png'),
            client: "Utility",
            urls: [
                require('../assets/case-study-3/cs-3-a.png'),
                require('../assets/case-study-3/cs-3-a.png'),
                require('../assets/case-study-3/cs-3-a.png')],
        },
        {
            id: 4,
            title: "Alfan Group",
            href: "#",
            description:
                "Apply easily and get our corporate card and powerful software - all for free. No credit checks or personal guarantees.",
            imageUrl: require('../assets/case-study-4/cs-4-a.png'),

            client: "Dashboard",
            urls: [
                require('../assets/case-study-4/cs-4-a.png'),
                require('../assets/case-study-4/cs-4-a.png'),
                require('../assets/case-study-4/cs-4-a.png')
            ],
        }
    ];
    return <section className="relative" id="work">
        <div className="mx-auto container px-6 py-16 md:py-24">
            <div className="grid grid-cols-1 gap-4">
                <DigitalAnimation>
                    <div className="text-center">
                       
                        <h2 className="text-5xl md:text-6xl font-bold text-black text-center">
                        Latest Work
                        </h2>
                        <div className="text-black font-2xl mb-4 mt-4">
                        Our Designs Have More Than 20 Billions+ Views On Dribble
                        </div>
                        <div className="pt-4">
                        <a target="_blank" class="px-6 sm:px-10 py-4 btn btn-secondary inline-block"
                        href='https://dribbble.com/dstudiotech'>View Work
                        </a>
                    </div>

                    </div>
                </DigitalAnimation>
                <DigitalAnimation>

                    
                </DigitalAnimation>

            </div>
            <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-y-20 gap-x-8 lg:mx-0 lg:max-w-none lg:grid-cols-2 text-left">
                {studies.map((post) => (
                    <CaseStudyCard key={post.id} post={post} />
                ))}
            </div>
        </div>
    </section>
}
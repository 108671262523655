import { Link } from "react-router-dom";
import logo from "../assets/logo.svg";
import logoIcon from "../assets/logo-icon.svg";
// import { LottoIcon } from "./helper/lottoIcon";
import { Bars2Icon, XMarkIcon } from "@heroicons/react/24/solid";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CALENDLY_URL } from "../constants";

export function HeaderComponent() {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const links=[{
        name:"Work",
        path:"/#work"
    },{
        name:"Pricing",
        path:"/#price"
    },{
        name:"Benefits",
        path:"/#benefits"
    },{
        name:"FAQ",
        path:"/#faq"
    }]

    // function routeNavigator(route){
    //     navigate(route.path)
    // }
    return <header>
        {/* <div className="bg-green-300">
            <div className="container mx-auto">
                <div className="flex items-center px-4 justify-center py-4 font-medium">
                    <p className="pe-2">
                        Get 20% OFF on Monthly Subscription - Onboard your best UI/UX
                        Team Now
                    </p>
                    <a href="#price">
                        <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.16666 7H12.8333"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M7 1.1665L12.8333 6.99984L7 12.8332"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </a>

                </div>
            </div>
        </div> */}
      


        <nav className="fixed w-full z-20 top-0 left-0 nav-top">
            <div className="container flex flex-wrap items-center justify-between mx-auto p-4">
                <Link to="/" className="-m-1.5 p-1.5 flex items-center md:min-w-[178px]">
                    <span className="sr-only">Dstudio Tech</span>
                    <img
                        className="h-10 w-auto pe-2 hidden md:inline-block"
                        src={logo}
                        alt="Dstudio Tech"
                    ></img>
                    <img
                        className="h-10 w-auto pe-2 inline-block md:hidden"
                        src={logoIcon}
                        alt="Dstudio Tech"
                    ></img>
                </Link>
                <div className="flex md:order-2">
                    <a
                        href={CALENDLY_URL} target="_blank"
                        className="btn btn-outline-light mr-3"
                        onClick={()=>setOpen(false)}
                    >
                        <span>Schedule a Call</span>
                    </a>

                    {/* <a
                        href="#price"
                        className="btn btn-outline-light"
                        onClick={()=>setOpen(false)}
                    >
                        Buy Plan{" "}
                    </a> */}
                    <button
                        data-collapse-toggle="navbar-sticky"
                        type="button"
                        className="inline-flex items-center p-0 text-sm text-gray-500 rounded-lg lg:hidden focus:outline-none"
                        aria-controls="navbar-sticky"
                        aria-expanded="false"
                        onClick={() => setOpen(!open)}
                    >
                        <span className="sr-only">Open main menu</span>
                        {open ? <XMarkIcon className="h-10 w-10"></XMarkIcon> : <Bars2Icon className="h-10 w-10"></Bars2Icon>}

                    </button>
                </div>

                <div className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
                    <ul className="navbar">
                        {
                            links.map(m=>{
                                return  <li>
                                <a href={m.path} className="">{m.name}</a>
                            </li>
                            })
                        }

                    </ul>
                </div>
            </div>
        </nav>
        {/* MOBILE SCREEN */}
        {open && <div class="navbar-mobile">
        <ul className="navbar-links">
                        {
                            links.map(m=>{
                                return  <li className="block w-full">
                                <a href={m.path} className="" onClick={()=>setOpen(false)}>{m.name}</a>
                            </li>
                            })
                        }

                    </ul>

        </div>}
    </header>
}
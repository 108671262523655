import { FooterComponent } from "./components/footer";
import { HeaderComponent } from "./components/header";
import { DigitalAnimation } from "./components/animation/digitalAnimation";
export function TermandConditionPage(){
    return <div>
       <HeaderComponent />
       <DigitalAnimation>
    <div className="container mx-auto px-4 py-12 md:py-20">
    <div className="text-center mt-12"><span class="hero-badge">Last updated: 05/23/2023</span></div>

      <h1 className="text-center">
      Terms of Use
      </h1>
    </div>
    </DigitalAnimation>
       <div class="container mx-auto px-4">

        {/* <p className="mt-6">
        THIS TERMS OF USE AGREEMENT (the “Agreement”) constitutes a legally binding agreement by and between Whale UI, LLC, a limited liability company organized under the laws stated Constitution of India (“Whale UI”) and the client, whether personally or on behalf of an entity (“Client”), with regard to access and use of Whale UI’s website: https://www.Whale UI.co/ (the “Website”) and any other media form, channel, mobile website or mobile application related, linked or otherwise connected thereto. Failure to agree and adhere to all of the terms, conditions and obligations contained herein results in the express prohibition of the Client’s use of the Website, and the Client is ordered to discontinue use immediately. Thereafter the relationship between Client and Whale UI shall cease and be of no further force and effect between the parties, except that any obligation of Client to pay Whale UI for services rendered shall remain and continue to be an ongoing obligation owed by Client to Whale UI.
        </p> */}
        
        
        <h2 className="mt-6 font-bold h4">
        1. Ownership of Intellectual Property
        </h2>
        <p className="mt-6">
        All elements of the Website, unless explicitly stated otherwise, are the exclusive property of Dstudiotech. This includes but is not limited to source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in any format (collectively referred to hereinafter as "Content"). Additionally, the trademarks, service marks, and logos present on the Website (referred to as "Marks") are the sole property of Dstudiotech. These assets are safeguarded by copyright and trademark laws, as well as other relevant intellectual property regulations within the United States, international jurisdictions, and global conventions.
        </p>
        <p className="mt-6">
        The provided Content and Marks are made available on an "As-Is" basis, exclusively for your personal use and informational purposes. With the exception of the permissions explicitly granted in this statement, you are expressly prohibited from engaging in any of the following activities with respect to the Website's Content or any portion thereof: copying, reproducing, aggregating, republishing, uploading, posting, displaying, encoding, translating, transmitting, distributing, selling, licensing, or otherwise exploiting these assets for any form of commercial gain, without the explicit prior written consent of Dstudiotech. Dstudiotech retains complete ownership and control over the Website, its Content, and associated Marks.
        </p>

        <h2 className="mt-6 font-bold h4">
        2. Client Ownership of Creations
        </h2>
        <p className="mt-6">
        Despite Dstudiotech's ownership of Submissions, as outlined in Section 4 ("Client Feedback"), all design and original source files produced on behalf of the Client ("Projects") shall be the exclusive property of the Client. The copyright for all Projects will be solely owned by the Client. Should any legal circumstance arise that could transfer ownership of a Project, whether partially or wholly, from the Client to Dstudiotech, Dstudiotech hereby assigns its complete interest in said Project to the Client in an irrevocable and perpetual manner, without any limitations.

        </p>
        <p className="mt-6">
        The Client affirms that any materials furnished to Dstudiotech as references or for integration into a project during the design process are the rightful property of the Client. These materials shall not infringe upon or misappropriate the rights of any third party, including but not limited to intellectual property rights and rights of publicity. Dstudiotech retains the right to publicly showcase the Client's design work, such as on social media or the Dstudiotech website, unless otherwise agreed upon as stipulated in section 18 of this document.
        </p>
        <h2 className="mt-6 font-bold h4">
        3. Usage of Third-Party Fonts
        </h2>
        <p className="mt-6">
        In scenarios where a Project employs fonts not owned by Dstudiotech and necessitating a commercial license for the legal reproduction, distribution, or public display of the Project ("Third-Party Font(s)"), Dstudiotech will communicate in writing to the Client about the integration of one or more Third-Party Fonts into the Project. The Client will be required to procure one or more licenses from the rights-holder(s) of the Third-Party Fonts to ensure legal reproduction, distribution, or public display of the Project. The notice provided will contain adequate details for the Client to identify the necessary licenses and the appropriate contacts for license acquisition. Assuming Dstudiotech notifies the Client about the inclusion of Third-Party Fonts as specified above, the Client assumes full responsibility for any consequences arising from the failure to obtain licenses for Third-Party Fonts included in a Project.
        </p>
        <h2 className="mt-6 font-bold h4">
        4.  Client Assertions
        </h2>
        <p className="mt-6">
          Through the utilization of the Website, the Client asserts and guarantees the following: the Client possesses the legal capacity and agrees to adhere to these Terms of Use; the Client is of legal age in their domicile jurisdiction and not a minor; the Client will not access the Website through automated or non-human means; the Client will refrain from using the Website for any illicit or unauthorized purposes; the Client's use of the Website will not breach any applicable laws or regulations.
        </p>
        <h2 className="mt-6 font-bold h4">
        5. Unauthorized Actions
        </h2>
        <p className="mt-6">
        The Client is permitted to access and use the Website solely for its intended purpose. Utilization of the Website is limited to activities directly associated with the work carried out by Dstudiotech on the Client's behalf. Furthermore, the Client undertakes not to engage in the following actions:

        </p>
        <ul className="mt-6 list-disc list-inside">
          <li>
          Utilize the Website in an unauthorized manner.
          </li>
          <li>
          Extract data or content with the intent to create or compile a database or directory.
          </li>
          <li>
          Disable, evade, or otherwise disrupt security features of the Website
          </li>
          <li>
          Participate in unauthorized framing or linking to the Website.

          </li>
          <li>
          Deceive, defraud, or mislead Dstudiotech or other users.

          </li>
          <li>
          Disrupt or overload the Website or Dstudiotech’s networks or servers.
          </li>
          <li>
          Attempt to compete with Dstudiotech using the Website.

          </li>
          <li>
          Reverse engineer, decompile, disassemble, or decipher any software forming part of the Website.

          </li>
          <li>
          Bypass measures on the Website meant to prevent or limit access.
            </li>
            <li>
            Harass, threaten, or intimidate Dstudiotech’s employees, contractors, or agents.
            </li>
            <li>
            Remove copyright or other rights notices from any Content.

            </li>
            <li>
            Duplicate or modify the Website’s software.

            </li>
            <li>
            Transmit viruses, Trojan horses, or disruptive material.

            </li>
            <li>
            Upload mechanisms for collecting or transmitting information.

            </li>
            <li>
            Engage in conduct that tarnishes Dstudiotech's reputation.

            </li>
            <li>
            Use the Website contrary to applicable laws, statutes, or regulations.
            </li>
        </ul>
        <h2 className="mt-6 font-bold h4">
        6.  Client Input
        </h2>
        <p className="mt-6">
        The Client acknowledges and accepts that any feedback, suggestions, or other submissions (each, a "Submission") become the exclusive property of Dstudiotech. Dstudiotech is not obligated to maintain the confidentiality of Submissions or take steps to ensure their confidentiality. Dstudiotech possesses complete ownership of all rights related to Submissions, except where Client rights are granted in Section 2 ("Client Ownership of Creations"). Dstudiotech retains the unrestricted discretion to use and share Submissions for lawful purposes without needing Client permission, recognition, or compensation. The Client affirms the right to provide Submissions and relinquishes any claims against Dstudiotech for Submission use in accordance with these terms and at Dstudiotech's sole discretion in the future.

        </p>
        <h2 className="mt-6 font-bold h4">
        7. Oversight and Administration
        </h2>
        <p className="mt-6">
        Dstudiotech maintains the authority to supervise the Website to identify violations of these Terms of Use. If any violation of the Terms of Use or applicable laws, statutes, or regulations is detected, Dstudiotech may take suitable legal measures in response. Additionally, Dstudiotech retains the prerogative to limit or revoke access to the Website or terminate the Client's use of the Website. This determination rests solely with Dstudiotech, and it may be executed without prior notification or incurring liability to the Client. All determinations concerning Website management fall under Dstudiotech's exclusive discretion and are designed to safeguard Dstudiotech's rights and assets.

        </p>

        <h2 className="mt-6 font-bold h4">
        8.  Data Privacy Policy

        </h2>
        <p className="mt-6">
        Through the utilization of the Website, the Client agrees to comply with and be bound by the terms and provisions set forth in the Privacy Policy. The Privacy Policy is specifically outlined therein and is incorporated into these terms. The Website is hosted within the United States of America. Accessing the Website from other regions, such as the EU or Asia, may render different laws, statutes, or regulations applicable to data collection, usage, or disclosure. Continued use of the Website and data transfer to the United States by the Client signifies explicit consent to data processing and transfer within the United States. Dstudiotech does not knowingly solicit or accept information from individuals under 18 years of age. In accordance with the United States' Children's Online Privacy Protection Act, upon receiving credible information that an individual under 13 years of age has supplied personally identifiable information to Dstudiotech without parental consent, Dstudiotech will promptly delete said information.
        </p>
        <h2 className="mt-6 font-bold h4">
        9. Refunds and Return Policy
        </h2>
        <p className="mt-6">
        We selectively accept projects for subscription to ensure our clients receive top-tier work. Because of the premium quality we provide, refunds are not offered at any case.        </p>
        {/* <p className="mt-6">
        Should a refund be deemed appropriate, a 25% fee will be applied to the remaining billable period. In circumstances where Dstudiotech deems it necessary, legal action may be pursued against the Client for breaches of this section.
        </p> */}
        
        {/* <h2 className="mt-6 font-bold h4">
        10. Modification
        </h2>
        <p className="mt-6">
        Whale UI reserves the right to change, alter, modify, amend or remove anything or any content on the Website for any reason at its sole discretion. Whale UI reserves the right to modify or discontinue all or part of the Website without notice and without liability to Client.
        </p> */}
        <h2 className="mt-6 font-bold h4">
        10. Disruptions in Connectivity
        </h2>
        <p className="mt-6">
        The consistent availability and accessibility of the Website cannot be guaranteed by Dstudiotech. Instances of hardware or software issues, among other factors, might lead to interruptions, delays, or errors that are beyond Dstudiotech's control. The Client acknowledges and agrees that Dstudiotech will not bear any responsibility for losses, damages, or inconveniences incurred due to the Client's inability to access or use the Website during periods of connection or service disruptions.
        </p>
        <h2 className="mt-6 font-bold h4">
        11 Jurisdiction and Applicable Law
        </h2>
        <p className="mt-6">
        The regulation and interpretation of these Terms of Use shall adhere to the laws of the State of Maryland, without consideration of conflicts of law principles.
        </p>

        <h2 className="mt-6 font-bold h4">
        12. Legal Proceedings
        </h2>
        <p className="mt-6">
        Any legal proceedings, regardless of their nature, shall be initiated in the state courts of Washington County, Maryland, or in the United States District Court for the District of Maryland. The involved parties provide consent to personal jurisdiction within these courts and renounce all objections regarding the absence of personal jurisdiction and forum non conveniens related to jurisdiction and venue. The application of the United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transaction Act are specifically excluded from the scope of these Terms of Use.
        </p>

        <h2 className="mt-6 font-bold h4">
        13. Exclusion of Warranties
        </h2>
        <p className="mt-6">
        The Website is made available in its present state and according to its availability. The Client acknowledges that their use of the Website and Services is solely at their own risk. Dstudiotech disclaims all warranties, whether express or implied, relating to the Website and the Client's utilization of it. This includes but is not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement. Dstudiotech makes no assurances or representations regarding the accuracy or comprehensiveness of the Website's content or the content of linked websites. Furthermore, Dstudiotech assumes no responsibility for content and material errors, personal injury, property damage, unauthorized access to secure servers or personal/financial data, disruptions in transmission, third-party transmission of bugs or viruses, or errors or omissions in content and materials. Dstudiotech also does not endorse, warrant, or assume responsibility for any products or services advertised or offered by third parties via the Website, hyperlinked sites, or any other featured platforms.
        </p>

        <h2 className="mt-6 font-bold h4">
        14. Limitation of Liability and Indemnification
        </h2>
        <p className="mt-6">
        Dstudiotech, its directors, employees, members, independent contractors, or agents shall not be held liable to the Client or any third party for direct, indirect, incidental, consequential, special, or punitive damages. This includes damages such as lost profits, lost revenue, lost data, attorney fees, court expenses, fines, forfeitures, or other types of damages or losses that arise from the Client's use of the Website. The Client agrees to defend, indemnify, and hold harmless Dstudiotech, its subsidiaries, affiliates, officers, members, agents, partners, employees, and independent contractors from any losses, damages, liabilities, claims, or demands, including reasonable attorney fees and costs, arising due to or in connection with: (1) Website usage; (2) breaches of these Terms of Use; (3) violations of Client's representations and warranties stated herein; (4) infringement on third-party rights, including intellectual property rights. However, Dstudiotech reserves the right, at the Client's expense, to assume control of the defense for matters requiring the Client's indemnification. The Client commits to cooperating in the defense of such claims.

</p>

<h2 className="mt-6 font-bold h4">
15. Client Data Responsibility
        </h2>
        <p className="mt-6">
        The Client bears full responsibility for all data transmitted in relation to any activities undertaken using the Website. Dstudiotech shall not be held liable to the Client for any loss or corruption of such data. The Client hereby relinquishes any right to take legal action against Dstudiotech for any loss or corruption of data.
        </p>

        <h2 className="mt-6 font-bold h4">
        16.  Electronic Correspondence, Transactions, and Signatures
        </h2>
        <p className="mt-6">
        The Client provides consent to receive electronic communications from Dstudiotech. The Client agrees that agreements, notifications, disclosures, and other communications delivered via email or through the Website fulfill any legal requirement for written communication. The Client hereby accepts the utilization of electronic signatures, contracts, orders, records, and electronic delivery of notifications, policies, and transaction records initiated or completed by Dstudiotech or via the Website. The Client waives any rights or obligations stipulated by statutes, regulations, rules, ordinances, or other laws in any jurisdiction that necessitate original signatures or non-electronic record retention or delivery, or that govern payments or credits through means other than electronic methods.
        </p>

        <h2 className="mt-6 font-bold h4">
        17. Presentation of Design Work
        </h2>
        <p className="mt-6">
        Dstudiotech retains the right to display design work on digital platforms, which may include social media and the Dstudiotech website, unless otherwise mutually agreed upon. The Client has the option to establish a Non-Disclosure Agreement (NDA) with Dstudiotech, rendering Dstudiotech unable to publicly share or discuss the Client's work.
        </p>

        {/* <h2 className="mt-6 font-bold h4">
        19. Miscellaneous
        </h2>
        <p className="mt-6">
        These Terms of Use and any policies posted on the Website or regarding the Website constitute the entire agreement and understanding between the Client and Whale UI. Failure of Whale UI to enforce any right or provision of these Terms of Use shall not operate as a waiver of such right or provision. If any provision or part of these Terms of Use is determined to be unlawful, void or unenforceable, that provision shall be severed from these Terms of Use but shall not otherwise affect the validity or unenforceability of the remaining provisions herein. Nothing in these Terms of Use, the Privacy Policy or on the Website shall be construed to constitute the forming of a joint venture, partnership, employment or agency relationship between Client and Whale UI.
        </p> */}

        <h2 className="mt-6 font-bold h4">
        18. Additional Points
        </h2>
        <p className="mt-6">
        The entirety of these Terms of Use, along with any policies published on the Website or concerning the Website, establishes the complete agreement and comprehension between Dstudiotech and the Client. Dstudiotech's failure to enforce any right or stipulation within these Terms of Use will not negate that right or stipulation. In the event that any provision or aspect of these Terms of Use is determined to be illegal, null, or unenforceable, that provision will be removed from these Terms of Use. However, the remaining provisions will remain valid and enforceable. Nothing in these Terms of Use, the Privacy Policy, or on the Website should be interpreted as forming a joint venture, partnership, employment, or agency relationship between the Client and Dstudiotech.
        </p>

        <h2 className="mt-6 font-bold h4">
        19. Contact Details
        </h2>
        <p className="mt-6 pb-24">
        For inquiries or grievances concerning the Website, please reach out to Dstudiotech via email: hello@dstudio.agency
        </p>

        </div>
        <FooterComponent></FooterComponent>
    </div>
}
import { DigitalAnimation } from "./animation/digitalAnimation";
import { Plans } from "./plans";
import React from 'react';

export function Services() {
  const plans = [{
    name: "Design Trial 🥳",
    text1: "One request at a time.",
    text2: "Two-week design trial to ensure work quality",
    amount: "$1,850 / 2w",
    text3: "What's included:",
    button: 'Subscribe to Plan',
    disabled: false,
    paymentLink: "https://buy.stripe.com/eVabL1enC2ONf0Q4gG",
    features: ["One Task For Design Trial",
      "Dedicated Designer",
      "Average 2-3 Days Delivery",
      "Unlimited Revisions (Till Plan Active)",
      "Two Weeks Trial",
      "Managed Via Slack And Trello"],
    services: ['Branding', 'Graphic', 'App Design', 'Web Design', 'Pitch Deck']
  },
  {
    recommended: true,
    name: "Standard 🌈",
    text1: "One request at a time.",
    text2: "No minimum commitment — Pause Or cancel anytime.",
    amount: "$4,250/m",
    text3: "What's included:",
    button: 'Subscribe to Plan',
    disabled: false,
    paymentLink: "https://buy.stripe.com/bIY5mD5R60GF9Gw8wU",
    features: ["One Request At A Time",
      "Dedicated Senior Designer",
      "Average 2-3 Days Delivery", "Unlimited Revisions", 'Unlimited Design Requests', "Managed Via Slack And Trello"],
    services: ['Branding', 'Graphic', 'Marketing', 'Web Design', 'Pitch Deck']

  },
  {
    name: "Pro 🔥",
    text1: "Two request at a time.",
    text2: "No minimum commitment — Pause Or cancel anytime.",
    amount: "$7,540",
    text3: "What's included:",
    button: 'Subscribe to Plan',
    disabled: false,
    popular: true,
    paymentLink: "https://buy.stripe.com/7sI6qH6VaexvdWMfZn",
    features: ["Two Request At A Time",
      "Two Designer & Project Manager",
      "Average 2-3 Days Delivery", "Unlimited Revisions", "Unlimited Design Requests", "Managed Via Slack And Trello"],
    services: ['Branding', 'App Design', 'Pitch Deck', 'Web App', 'Product Design', 'UX Strategy', 'MVP', 'Landing Page', 'Design System']

  },
  {
    name: "Project - Based 🧶",
    text1: "Custom project scope",
    text2: "50% Upfront, 50% on final",
    addBr1: true,
    amount: "From $9,750",
    text3: "What's included:",
    button: 'Request Quote',
    disabled: true,
    paymentLink: "https://buy.stripe.com/00g4izgvK753dWMbJ9",
    features: ["Customised Scope Per Project", "Dedicate Team", "Fixed Deadline", "Upto 4 Iterations Included", "Design, Code & Deployment", "Managed Via Slack And Trello"],
    services: ['Fronend Development', 'Webflow', 'App Design', 'Website from scratch', 'HTML & CSS', 'Elementor', 'Javascript', 'Design System', 'Web Development']

  }]
  return <section id="price" className="pricing py-16 md:py-32">
    <div className="container mx-auto px-4">
      <DigitalAnimation>
        <span className="hero-badge mb-6">No Hidden Charge</span>
        <h2 className="text-5xl md:text-6xl font-bold text-black mb-4">
          Flexible Plans
        </h2>
        <div className="text-black font-medium ">
          Interested? <a href="https://calendly.com/aakash-dstudio/30min" target="_blank" className="text-dark font-semibold underline" target="_blank">Schedule A call</a> Today!
        </div>
      </DigitalAnimation>

      <DigitalAnimation>

        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 mt-20 text-left gap-1.5">
          <Plans params={plans[0]} />
          <Plans params={plans[1]} />
          <Plans params={plans[2]} />
          <Plans params={plans[3]} />

          {/* <div className="md:col-span-3 lg:col-span-1">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1">
              <div className="group p-8 bg-yellow-200 md:rounded-tr-3xl">
                <div>
                  <svg
                    width="72"
                    height="72"
                    viewBox="0 0 72 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="72" height="72" rx="36" fill="white" className="group-hover:fill-green-200 transition-all ease-in" />
                    <g clipPath="url(#clip0_1_340)">
                      <path
                        d="M40 25C41.6819 25.4429 43.2161 26.3243 44.4459 27.5541C45.6757 28.7839 46.5571 30.3181 47 32"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M39 29C41.065 29.5525 42.4475 30.935 43 33"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M40.5488 38.168C40.6872 38.0758 40.8465 38.0197 41.0122 38.0046C41.1779 37.9895 41.3447 38.016 41.4975 38.0817L47.3925 40.723C47.5912 40.8079 47.757 40.9548 47.8651 41.1419C47.9733 41.3289 48.018 41.5459 47.9925 41.7605C47.7983 43.2118 47.0836 44.5432 45.9813 45.507C44.8791 46.4709 43.4642 47.0016 42 47.0005C37.4913 47.0005 33.1673 45.2094 29.9792 42.0213C26.7911 38.8332 25 34.5091 25 30.0005C24.9989 28.5362 25.5296 27.1214 26.4935 26.0191C27.4573 24.9169 28.7887 24.2022 30.24 24.008C30.4545 23.9825 30.6716 24.0272 30.8586 24.1353C31.0456 24.2435 31.1926 24.4093 31.2775 24.608L33.9188 30.508C33.9836 30.6595 34.0101 30.8247 33.9957 30.9889C33.9813 31.1532 33.9265 31.3113 33.8363 31.4492L31.165 34.6255C31.0702 34.7684 31.0142 34.9336 31.0024 35.1047C30.9906 35.2758 31.0233 35.4471 31.0975 35.6017C32.1313 37.718 34.3188 39.8792 36.4413 40.903C36.5967 40.9768 36.7688 41.0089 36.9404 40.9959C37.112 40.983 37.2773 40.9255 37.42 40.8292L40.5488 38.168Z"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_340">
                        <rect
                          width="32"
                          height="32"
                          fill="white"
                          transform="translate(20 20)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="mt-6 text-xl font-medium">Book a call</div>
                <p className="mt-2 mb-4 text-sm">
                  Learn more about how we works and how it can help you.
                </p>
                <p className="inline-block">
                  <a
                    href="https://calendly.com//30min" target="_blank"
                    className="flex py-2 rounded-full text-black flex items-center font-semibold"
                  >
                    <span >Book Now</span>
                    <svg
                      className="ms-2 animate-bounce"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.83334 10.1668L10.1667 1.8335"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M1.83334 1.8335H10.1667V10.1668"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </a>
                </p>
              </div>
              <div className="group p-8 bg-violet-300 md:rounded-br-3xl">
                <div>
                  <svg
                    width="72"
                    height="72"
                    viewBox="0 0 72 72"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="72" height="72" rx="36" fill="white" className="group-hover:fill-green-200 transition-all ease-in" />
                    <g clipPath="url(#clip0_1_347)">
                      <path
                        d="M21.2788 44.9997C22.2777 43.4639 23.6443 42.2019 25.2546 41.3283C26.865 40.4547 28.668 39.9971 30.5001 39.9971C32.3321 39.9971 34.1351 40.4547 35.7455 41.3283C37.3558 42.2019 38.7224 43.4639 39.7213 44.9997"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M41.5 40C43.332 39.9989 45.1351 40.4558 46.7456 41.329C48.356 42.2022 49.7227 43.4641 50.7213 45"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M30.5 40C34.0899 40 37 37.0899 37 33.5C37 29.9101 34.0899 27 30.5 27C26.9101 27 24 29.9101 24 33.5C24 37.0899 26.9101 40 30.5 40Z"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M39.0862 27.4625C39.9754 27.1079 40.9319 26.954 41.8874 27.0119C42.8429 27.0698 43.7738 27.338 44.6136 27.7974C45.4535 28.2568 46.1815 28.896 46.7456 29.6694C47.3097 30.4428 47.6961 31.3312 47.8771 32.2712C48.058 33.2112 48.0291 34.1795 47.7925 35.1071C47.5558 36.0346 47.1172 36.8984 46.5079 37.6368C45.8987 38.3752 45.1339 38.9698 44.2682 39.3784C43.4025 39.7869 42.4572 39.9992 41.5 40"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_347">
                        <rect
                          width="32"
                          height="32"
                          fill="white"
                          transform="translate(20 20)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div className="mt-6 text-xl font-medium">
                  Refer a friend & earn
                </div>
                <p className="mt-2 mb-4 text-sm">
                  Learn more about how we works and how it can help you.
                </p>
                <p className="inline-block">
                  <span
                    className="flex py-2 rounded-full text-black flex items-center font-semibold"
                  >
                    <span>Coming Soon</span>
                    <svg
                      className="ms-2 animate-bounce"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.83334 10.1668L10.1667 1.8335"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M1.83334 1.8335H10.1667V10.1668"
                        stroke="black"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </span>
                </p>
              </div>
            </div>
          </div> */}
        </div>

      </DigitalAnimation>
      <DigitalAnimation>

        {/* <div className="mt-20 bg-black rounded-3xl p-8 md:p-20">
          <div className="grid grid-cols-1 md:grid-cols-2 ">
            <div className="text-left text-white">
              <p className="text-green-300 pb-7">Design Trial</p>
              <h3 className="text-5xl font-bold">Try Our Design Service for 48 Hours</h3>
              <div className="mt-6 text-xl text-gray-400">Evaluate our design excellence before committing to any subscription.</div>
            </div>
            <div className="text-left md:text-right">
              <a
                // href="https://buy.stripe.com/28oeXdcfugFD1a0dQT"
                href={getMailLink({name:"Beginners",amount:"499 for 48 hours"})}
                // style={{pointerEvents:"none"}}
                className="group-hover:bg-black group-hover:text-green-300 mt-8 transition-all ease-in-out ease-in duration-75 border border-black rounded-full bg-white hover:bg-green-300 hover:text-black inline-block mt-12 md:mt-24 text-center px-8 py-3 font-medium"
              >
                Get Started ⎯ $499
              </a>
            </div>
          </div>
        </div> */}
      </DigitalAnimation>
    </div>
  </section>
}
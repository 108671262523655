import React from 'react';
import { DigitalAnimation } from './animation/digitalAnimation';
import { LottoIcon } from './helper/lottoIcon';
import { CALENDLY_URL } from '../constants';

export function Stats() {
  return (
    <section className="stats py-16 md:py-28">
      <div className="container mx-auto px-4">
        <DigitalAnimation>
          {/* <div className="text-center text-sm uppercase mb-2">
            Who is this designed for
          </div> */}
          <p className='hero-badge'>
          Super - Easy
          </p>
          <h3 className="text-5xl font-bold text-black text-center mt-2">
          Work Process
          </h3>
          <p className='mt-4'>We adhere to a straightforward procedure from onboarding to project completion</p>
        </DigitalAnimation>

        <DigitalAnimation>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-14 md:gap-20 mt-16">
            <div className="text-center">
              {/* <h2 className="text-7xl font-bold">$75M</h2> */}
              <div>
                <span className='inline-block h-40 w-40 rounded-full bg-white '>
                <LottoIcon type='Cart-Add_lottie'></LottoIcon>
                </span>
              </div>
              <div className="text-2xl font-semibold mt-4">
              Subscribe to Plan
              </div>
              <p className="mt-2 max-w-[260px] m-auto">
              Choose any of the plan which suits you the best.
             </p>
            </div>

            <div className="text-center">
              {/* <h2 className="text-7xl font-bold">$104M</h2> */}
              <div>
              <span className='inline-block h-40 w-40 rounded-full bg-white '>
                <LottoIcon type='Carousel-Horizontal_lottie'></LottoIcon>
                </span>
              </div>
              <div className="text-2xl font-semibold mt-4">
              Add Your Design Task
              </div>
              <p className="mt-2 max-w-[260px] m-auto">
              Put all the task on Trello, Slack
or loom video.
              </p>
            </div>

            <div className="text-center">
              {/* <h2 className="text-7xl font-bold">2022</h2> */}
              <div>
              <span className='inline-block h-40 w-40 rounded-full bg-white '>
                <LottoIcon type='Circle-Down_lottie'></LottoIcon>
                </span>
              </div>
              <div className="text-2xl font-semibold mt-4">
              Lightening Speed Delivery
              </div>
              <p className="mt-2 max-w-[260px] m-auto">
              Get you design updates in just
24-48 Hours turnaround
              </p>
            </div>
          </div>
        </DigitalAnimation>
        
      </div>
      <DigitalAnimation>
          <div className='text-center pt-24 text-lg'>
          Did We Miss Something? <a href={CALENDLY_URL} target="_blank" className='text-dark underline font-semibold'>Chat With Us</a> Today!
          </div>
        </DigitalAnimation>
    </section>
  )
}
import React from "react";
import { DigitalAnimation } from "./animation/digitalAnimation";
// import star from "../assets/Star.svg";

export function Designedfor() {
  return (

    <section className="pt-28">
      <DigitalAnimation>

        <div className="container mx-auto px-4 relative">
        <div className="text-center">
              <p className="hero-badge">Our Design Reviews</p>
              <h2 className="text-5xl font-bold">
              Reviews From Our <br className="hidden-mobile"></br>Client
              </h2>
            </div>
          <div className="review mt-16">
          <div className="review-bg"> 

          </div>
          <div className="grid grid-cols-1 gap-8 -mt-6">
            <div>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl text-left mx-auto'>
                  <div className="grid grid-cols-1 gap-10">
                    <DigitalAnimation>
                        <div className="review-box-sec">
                            <div className="stars">
                              
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20.466 20.466">
  <path id="star-svgrepo-com" d="M9.32,5.488C10.616,3.163,11.264,2,12.233,2s1.617,1.163,2.913,3.488l.335.6a3.754,3.754,0,0,0,.84,1.209,3.5,3.5,0,0,0,1.36.461l.651.147c2.517.569,3.775.854,4.075,1.817s-.559,1.966-2.274,3.973l-.444.519a3.767,3.767,0,0,0-.841,1.208,3.931,3.931,0,0,0,0,1.494l.067.693c.259,2.677.389,4.016-.395,4.611s-1.962.052-4.319-1.032l-.61-.281a3.491,3.491,0,0,0-1.359-.463,3.491,3.491,0,0,0-1.359.463l-.61.281c-2.357,1.085-3.535,1.628-4.319,1.032s-.654-1.934-.395-4.611l.067-.693a3.928,3.928,0,0,0,0-1.494,3.766,3.766,0,0,0-.841-1.208L4.333,13.7c-1.716-2.006-2.574-3.01-2.274-3.973S3.617,8.476,6.134,7.906l.651-.147A3.5,3.5,0,0,0,8.145,7.3a3.754,3.754,0,0,0,.84-1.209Z" transform="translate(-2 -2)" fill="#5eadf4"/>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20.466 20.466">
  <path id="star-svgrepo-com" d="M9.32,5.488C10.616,3.163,11.264,2,12.233,2s1.617,1.163,2.913,3.488l.335.6a3.754,3.754,0,0,0,.84,1.209,3.5,3.5,0,0,0,1.36.461l.651.147c2.517.569,3.775.854,4.075,1.817s-.559,1.966-2.274,3.973l-.444.519a3.767,3.767,0,0,0-.841,1.208,3.931,3.931,0,0,0,0,1.494l.067.693c.259,2.677.389,4.016-.395,4.611s-1.962.052-4.319-1.032l-.61-.281a3.491,3.491,0,0,0-1.359-.463,3.491,3.491,0,0,0-1.359.463l-.61.281c-2.357,1.085-3.535,1.628-4.319,1.032s-.654-1.934-.395-4.611l.067-.693a3.928,3.928,0,0,0,0-1.494,3.766,3.766,0,0,0-.841-1.208L4.333,13.7c-1.716-2.006-2.574-3.01-2.274-3.973S3.617,8.476,6.134,7.906l.651-.147A3.5,3.5,0,0,0,8.145,7.3a3.754,3.754,0,0,0,.84-1.209Z" transform="translate(-2 -2)" fill="#5eadf4"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20.466 20.466">
  <path id="star-svgrepo-com" d="M9.32,5.488C10.616,3.163,11.264,2,12.233,2s1.617,1.163,2.913,3.488l.335.6a3.754,3.754,0,0,0,.84,1.209,3.5,3.5,0,0,0,1.36.461l.651.147c2.517.569,3.775.854,4.075,1.817s-.559,1.966-2.274,3.973l-.444.519a3.767,3.767,0,0,0-.841,1.208,3.931,3.931,0,0,0,0,1.494l.067.693c.259,2.677.389,4.016-.395,4.611s-1.962.052-4.319-1.032l-.61-.281a3.491,3.491,0,0,0-1.359-.463,3.491,3.491,0,0,0-1.359.463l-.61.281c-2.357,1.085-3.535,1.628-4.319,1.032s-.654-1.934-.395-4.611l.067-.693a3.928,3.928,0,0,0,0-1.494,3.766,3.766,0,0,0-.841-1.208L4.333,13.7c-1.716-2.006-2.574-3.01-2.274-3.973S3.617,8.476,6.134,7.906l.651-.147A3.5,3.5,0,0,0,8.145,7.3a3.754,3.754,0,0,0,.84-1.209Z" transform="translate(-2 -2)" fill="#5eadf4"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20.466 20.466">
  <path id="star-svgrepo-com" d="M9.32,5.488C10.616,3.163,11.264,2,12.233,2s1.617,1.163,2.913,3.488l.335.6a3.754,3.754,0,0,0,.84,1.209,3.5,3.5,0,0,0,1.36.461l.651.147c2.517.569,3.775.854,4.075,1.817s-.559,1.966-2.274,3.973l-.444.519a3.767,3.767,0,0,0-.841,1.208,3.931,3.931,0,0,0,0,1.494l.067.693c.259,2.677.389,4.016-.395,4.611s-1.962.052-4.319-1.032l-.61-.281a3.491,3.491,0,0,0-1.359-.463,3.491,3.491,0,0,0-1.359.463l-.61.281c-2.357,1.085-3.535,1.628-4.319,1.032s-.654-1.934-.395-4.611l.067-.693a3.928,3.928,0,0,0,0-1.494,3.766,3.766,0,0,0-.841-1.208L4.333,13.7c-1.716-2.006-2.574-3.01-2.274-3.973S3.617,8.476,6.134,7.906l.651-.147A3.5,3.5,0,0,0,8.145,7.3a3.754,3.754,0,0,0,.84-1.209Z" transform="translate(-2 -2)" fill="#5eadf4"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20.466 20.466">
  <path id="star-svgrepo-com" d="M9.32,5.488C10.616,3.163,11.264,2,12.233,2s1.617,1.163,2.913,3.488l.335.6a3.754,3.754,0,0,0,.84,1.209,3.5,3.5,0,0,0,1.36.461l.651.147c2.517.569,3.775.854,4.075,1.817s-.559,1.966-2.274,3.973l-.444.519a3.767,3.767,0,0,0-.841,1.208,3.931,3.931,0,0,0,0,1.494l.067.693c.259,2.677.389,4.016-.395,4.611s-1.962.052-4.319-1.032l-.61-.281a3.491,3.491,0,0,0-1.359-.463,3.491,3.491,0,0,0-1.359.463l-.61.281c-2.357,1.085-3.535,1.628-4.319,1.032s-.654-1.934-.395-4.611l.067-.693a3.928,3.928,0,0,0,0-1.494,3.766,3.766,0,0,0-.841-1.208L4.333,13.7c-1.716-2.006-2.574-3.01-2.274-3.973S3.617,8.476,6.134,7.906l.651-.147A3.5,3.5,0,0,0,8.145,7.3a3.754,3.754,0,0,0,.84-1.209Z" transform="translate(-2 -2)" fill="#5eadf4"/>
</svg>
                            </div>
                            <div className="review-message">
                            They went above and beyond to deliver exceptional design. Highly recommended 🤟                            </div>
                            <div className="review-profile mt-12">
                                <div className="img">
                                <img className="h-14 w-14" alt='img' src={require('../assets/client1.jpeg')}></img>
                                </div>
                                <div>
                                  <div className="review-profile-h">
                                  John Xie
                                  </div>
                                  <div className="review-profile-p truncate">
                                  CEO - Taskade
                                  </div>
                                </div>
                            </div>
                            
                        </div>
                        </DigitalAnimation>
                        <DigitalAnimation>
                        <div className="review-box-sec">
                            <div className="stars">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20.466 20.466">
  <path id="star-svgrepo-com" d="M9.32,5.488C10.616,3.163,11.264,2,12.233,2s1.617,1.163,2.913,3.488l.335.6a3.754,3.754,0,0,0,.84,1.209,3.5,3.5,0,0,0,1.36.461l.651.147c2.517.569,3.775.854,4.075,1.817s-.559,1.966-2.274,3.973l-.444.519a3.767,3.767,0,0,0-.841,1.208,3.931,3.931,0,0,0,0,1.494l.067.693c.259,2.677.389,4.016-.395,4.611s-1.962.052-4.319-1.032l-.61-.281a3.491,3.491,0,0,0-1.359-.463,3.491,3.491,0,0,0-1.359.463l-.61.281c-2.357,1.085-3.535,1.628-4.319,1.032s-.654-1.934-.395-4.611l.067-.693a3.928,3.928,0,0,0,0-1.494,3.766,3.766,0,0,0-.841-1.208L4.333,13.7c-1.716-2.006-2.574-3.01-2.274-3.973S3.617,8.476,6.134,7.906l.651-.147A3.5,3.5,0,0,0,8.145,7.3a3.754,3.754,0,0,0,.84-1.209Z" transform="translate(-2 -2)" fill="#5eadf4"/>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20.466 20.466">
  <path id="star-svgrepo-com" d="M9.32,5.488C10.616,3.163,11.264,2,12.233,2s1.617,1.163,2.913,3.488l.335.6a3.754,3.754,0,0,0,.84,1.209,3.5,3.5,0,0,0,1.36.461l.651.147c2.517.569,3.775.854,4.075,1.817s-.559,1.966-2.274,3.973l-.444.519a3.767,3.767,0,0,0-.841,1.208,3.931,3.931,0,0,0,0,1.494l.067.693c.259,2.677.389,4.016-.395,4.611s-1.962.052-4.319-1.032l-.61-.281a3.491,3.491,0,0,0-1.359-.463,3.491,3.491,0,0,0-1.359.463l-.61.281c-2.357,1.085-3.535,1.628-4.319,1.032s-.654-1.934-.395-4.611l.067-.693a3.928,3.928,0,0,0,0-1.494,3.766,3.766,0,0,0-.841-1.208L4.333,13.7c-1.716-2.006-2.574-3.01-2.274-3.973S3.617,8.476,6.134,7.906l.651-.147A3.5,3.5,0,0,0,8.145,7.3a3.754,3.754,0,0,0,.84-1.209Z" transform="translate(-2 -2)" fill="#5eadf4"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20.466 20.466">
  <path id="star-svgrepo-com" d="M9.32,5.488C10.616,3.163,11.264,2,12.233,2s1.617,1.163,2.913,3.488l.335.6a3.754,3.754,0,0,0,.84,1.209,3.5,3.5,0,0,0,1.36.461l.651.147c2.517.569,3.775.854,4.075,1.817s-.559,1.966-2.274,3.973l-.444.519a3.767,3.767,0,0,0-.841,1.208,3.931,3.931,0,0,0,0,1.494l.067.693c.259,2.677.389,4.016-.395,4.611s-1.962.052-4.319-1.032l-.61-.281a3.491,3.491,0,0,0-1.359-.463,3.491,3.491,0,0,0-1.359.463l-.61.281c-2.357,1.085-3.535,1.628-4.319,1.032s-.654-1.934-.395-4.611l.067-.693a3.928,3.928,0,0,0,0-1.494,3.766,3.766,0,0,0-.841-1.208L4.333,13.7c-1.716-2.006-2.574-3.01-2.274-3.973S3.617,8.476,6.134,7.906l.651-.147A3.5,3.5,0,0,0,8.145,7.3a3.754,3.754,0,0,0,.84-1.209Z" transform="translate(-2 -2)" fill="#5eadf4"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20.466 20.466">
  <path id="star-svgrepo-com" d="M9.32,5.488C10.616,3.163,11.264,2,12.233,2s1.617,1.163,2.913,3.488l.335.6a3.754,3.754,0,0,0,.84,1.209,3.5,3.5,0,0,0,1.36.461l.651.147c2.517.569,3.775.854,4.075,1.817s-.559,1.966-2.274,3.973l-.444.519a3.767,3.767,0,0,0-.841,1.208,3.931,3.931,0,0,0,0,1.494l.067.693c.259,2.677.389,4.016-.395,4.611s-1.962.052-4.319-1.032l-.61-.281a3.491,3.491,0,0,0-1.359-.463,3.491,3.491,0,0,0-1.359.463l-.61.281c-2.357,1.085-3.535,1.628-4.319,1.032s-.654-1.934-.395-4.611l.067-.693a3.928,3.928,0,0,0,0-1.494,3.766,3.766,0,0,0-.841-1.208L4.333,13.7c-1.716-2.006-2.574-3.01-2.274-3.973S3.617,8.476,6.134,7.906l.651-.147A3.5,3.5,0,0,0,8.145,7.3a3.754,3.754,0,0,0,.84-1.209Z" transform="translate(-2 -2)" fill="#5eadf4"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20.466 20.466">
  <path id="star-svgrepo-com" d="M9.32,5.488C10.616,3.163,11.264,2,12.233,2s1.617,1.163,2.913,3.488l.335.6a3.754,3.754,0,0,0,.84,1.209,3.5,3.5,0,0,0,1.36.461l.651.147c2.517.569,3.775.854,4.075,1.817s-.559,1.966-2.274,3.973l-.444.519a3.767,3.767,0,0,0-.841,1.208,3.931,3.931,0,0,0,0,1.494l.067.693c.259,2.677.389,4.016-.395,4.611s-1.962.052-4.319-1.032l-.61-.281a3.491,3.491,0,0,0-1.359-.463,3.491,3.491,0,0,0-1.359.463l-.61.281c-2.357,1.085-3.535,1.628-4.319,1.032s-.654-1.934-.395-4.611l.067-.693a3.928,3.928,0,0,0,0-1.494,3.766,3.766,0,0,0-.841-1.208L4.333,13.7c-1.716-2.006-2.574-3.01-2.274-3.973S3.617,8.476,6.134,7.906l.651-.147A3.5,3.5,0,0,0,8.145,7.3a3.754,3.754,0,0,0,.84-1.209Z" transform="translate(-2 -2)" fill="#5eadf4"/>
</svg>
                            </div>
                            <div className="review-message">
                            We were impressed with their speed, design quality, and understanding.
                            </div>

                            <div className="review-profile mt-6">
                                <div className="img">
                                <img className="h-14 w-14" alt='img' src={require('../assets/client3.jpeg')}></img>
                                </div>
                                <div>
                                  <div className="review-profile-h">
                                  Danielle Tschudi
                                  </div>
                                  <div className="review-profile-p truncate">
                                  CEO & Founder, Outshifter
                                  </div>
                                </div>
                            </div>
                        </div>
                        </DigitalAnimation>
                  </div>
                  <div className="grid grid-cols-1 gap-10">
                  <DigitalAnimation>
                        <div className="review-box-sec">
                            <div className="stars">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20.466 20.466">
  <path id="star-svgrepo-com" d="M9.32,5.488C10.616,3.163,11.264,2,12.233,2s1.617,1.163,2.913,3.488l.335.6a3.754,3.754,0,0,0,.84,1.209,3.5,3.5,0,0,0,1.36.461l.651.147c2.517.569,3.775.854,4.075,1.817s-.559,1.966-2.274,3.973l-.444.519a3.767,3.767,0,0,0-.841,1.208,3.931,3.931,0,0,0,0,1.494l.067.693c.259,2.677.389,4.016-.395,4.611s-1.962.052-4.319-1.032l-.61-.281a3.491,3.491,0,0,0-1.359-.463,3.491,3.491,0,0,0-1.359.463l-.61.281c-2.357,1.085-3.535,1.628-4.319,1.032s-.654-1.934-.395-4.611l.067-.693a3.928,3.928,0,0,0,0-1.494,3.766,3.766,0,0,0-.841-1.208L4.333,13.7c-1.716-2.006-2.574-3.01-2.274-3.973S3.617,8.476,6.134,7.906l.651-.147A3.5,3.5,0,0,0,8.145,7.3a3.754,3.754,0,0,0,.84-1.209Z" transform="translate(-2 -2)" fill="#5eadf4"/>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20.466 20.466">
  <path id="star-svgrepo-com" d="M9.32,5.488C10.616,3.163,11.264,2,12.233,2s1.617,1.163,2.913,3.488l.335.6a3.754,3.754,0,0,0,.84,1.209,3.5,3.5,0,0,0,1.36.461l.651.147c2.517.569,3.775.854,4.075,1.817s-.559,1.966-2.274,3.973l-.444.519a3.767,3.767,0,0,0-.841,1.208,3.931,3.931,0,0,0,0,1.494l.067.693c.259,2.677.389,4.016-.395,4.611s-1.962.052-4.319-1.032l-.61-.281a3.491,3.491,0,0,0-1.359-.463,3.491,3.491,0,0,0-1.359.463l-.61.281c-2.357,1.085-3.535,1.628-4.319,1.032s-.654-1.934-.395-4.611l.067-.693a3.928,3.928,0,0,0,0-1.494,3.766,3.766,0,0,0-.841-1.208L4.333,13.7c-1.716-2.006-2.574-3.01-2.274-3.973S3.617,8.476,6.134,7.906l.651-.147A3.5,3.5,0,0,0,8.145,7.3a3.754,3.754,0,0,0,.84-1.209Z" transform="translate(-2 -2)" fill="#5eadf4"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20.466 20.466">
  <path id="star-svgrepo-com" d="M9.32,5.488C10.616,3.163,11.264,2,12.233,2s1.617,1.163,2.913,3.488l.335.6a3.754,3.754,0,0,0,.84,1.209,3.5,3.5,0,0,0,1.36.461l.651.147c2.517.569,3.775.854,4.075,1.817s-.559,1.966-2.274,3.973l-.444.519a3.767,3.767,0,0,0-.841,1.208,3.931,3.931,0,0,0,0,1.494l.067.693c.259,2.677.389,4.016-.395,4.611s-1.962.052-4.319-1.032l-.61-.281a3.491,3.491,0,0,0-1.359-.463,3.491,3.491,0,0,0-1.359.463l-.61.281c-2.357,1.085-3.535,1.628-4.319,1.032s-.654-1.934-.395-4.611l.067-.693a3.928,3.928,0,0,0,0-1.494,3.766,3.766,0,0,0-.841-1.208L4.333,13.7c-1.716-2.006-2.574-3.01-2.274-3.973S3.617,8.476,6.134,7.906l.651-.147A3.5,3.5,0,0,0,8.145,7.3a3.754,3.754,0,0,0,.84-1.209Z" transform="translate(-2 -2)" fill="#5eadf4"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20.466 20.466">
  <path id="star-svgrepo-com" d="M9.32,5.488C10.616,3.163,11.264,2,12.233,2s1.617,1.163,2.913,3.488l.335.6a3.754,3.754,0,0,0,.84,1.209,3.5,3.5,0,0,0,1.36.461l.651.147c2.517.569,3.775.854,4.075,1.817s-.559,1.966-2.274,3.973l-.444.519a3.767,3.767,0,0,0-.841,1.208,3.931,3.931,0,0,0,0,1.494l.067.693c.259,2.677.389,4.016-.395,4.611s-1.962.052-4.319-1.032l-.61-.281a3.491,3.491,0,0,0-1.359-.463,3.491,3.491,0,0,0-1.359.463l-.61.281c-2.357,1.085-3.535,1.628-4.319,1.032s-.654-1.934-.395-4.611l.067-.693a3.928,3.928,0,0,0,0-1.494,3.766,3.766,0,0,0-.841-1.208L4.333,13.7c-1.716-2.006-2.574-3.01-2.274-3.973S3.617,8.476,6.134,7.906l.651-.147A3.5,3.5,0,0,0,8.145,7.3a3.754,3.754,0,0,0,.84-1.209Z" transform="translate(-2 -2)" fill="#5eadf4"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20.466 20.466">
  <path id="star-svgrepo-com" d="M9.32,5.488C10.616,3.163,11.264,2,12.233,2s1.617,1.163,2.913,3.488l.335.6a3.754,3.754,0,0,0,.84,1.209,3.5,3.5,0,0,0,1.36.461l.651.147c2.517.569,3.775.854,4.075,1.817s-.559,1.966-2.274,3.973l-.444.519a3.767,3.767,0,0,0-.841,1.208,3.931,3.931,0,0,0,0,1.494l.067.693c.259,2.677.389,4.016-.395,4.611s-1.962.052-4.319-1.032l-.61-.281a3.491,3.491,0,0,0-1.359-.463,3.491,3.491,0,0,0-1.359.463l-.61.281c-2.357,1.085-3.535,1.628-4.319,1.032s-.654-1.934-.395-4.611l.067-.693a3.928,3.928,0,0,0,0-1.494,3.766,3.766,0,0,0-.841-1.208L4.333,13.7c-1.716-2.006-2.574-3.01-2.274-3.973S3.617,8.476,6.134,7.906l.651-.147A3.5,3.5,0,0,0,8.145,7.3a3.754,3.754,0,0,0,.84-1.209Z" transform="translate(-2 -2)" fill="#5eadf4"/>
</svg>
                            </div>
                            <div className="review-message">
                            They use real experts and masters to get the jobs done.
                            </div>


                            <div className="review-profile mt-6">
                                <div className="img">
                                <img className="h-14 w-14" alt='img' src={require('../assets/client2.jpeg')}></img>
                                </div>
                                <div>
                                  <div className="review-profile-h">
                                  Fred Cooper
                                  </div>
                                  <div className="review-profile-p truncate">
                                  CEO & Founder, Kwik & Ariix
                                  </div>
                                </div>
                            </div>
                            
                        </div>
                        </DigitalAnimation>
                        <DigitalAnimation>
                        <div className="review-box-sec">
                            <div className="stars">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20.466 20.466">
  <path id="star-svgrepo-com" d="M9.32,5.488C10.616,3.163,11.264,2,12.233,2s1.617,1.163,2.913,3.488l.335.6a3.754,3.754,0,0,0,.84,1.209,3.5,3.5,0,0,0,1.36.461l.651.147c2.517.569,3.775.854,4.075,1.817s-.559,1.966-2.274,3.973l-.444.519a3.767,3.767,0,0,0-.841,1.208,3.931,3.931,0,0,0,0,1.494l.067.693c.259,2.677.389,4.016-.395,4.611s-1.962.052-4.319-1.032l-.61-.281a3.491,3.491,0,0,0-1.359-.463,3.491,3.491,0,0,0-1.359.463l-.61.281c-2.357,1.085-3.535,1.628-4.319,1.032s-.654-1.934-.395-4.611l.067-.693a3.928,3.928,0,0,0,0-1.494,3.766,3.766,0,0,0-.841-1.208L4.333,13.7c-1.716-2.006-2.574-3.01-2.274-3.973S3.617,8.476,6.134,7.906l.651-.147A3.5,3.5,0,0,0,8.145,7.3a3.754,3.754,0,0,0,.84-1.209Z" transform="translate(-2 -2)" fill="#5eadf4"/>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20.466 20.466">
  <path id="star-svgrepo-com" d="M9.32,5.488C10.616,3.163,11.264,2,12.233,2s1.617,1.163,2.913,3.488l.335.6a3.754,3.754,0,0,0,.84,1.209,3.5,3.5,0,0,0,1.36.461l.651.147c2.517.569,3.775.854,4.075,1.817s-.559,1.966-2.274,3.973l-.444.519a3.767,3.767,0,0,0-.841,1.208,3.931,3.931,0,0,0,0,1.494l.067.693c.259,2.677.389,4.016-.395,4.611s-1.962.052-4.319-1.032l-.61-.281a3.491,3.491,0,0,0-1.359-.463,3.491,3.491,0,0,0-1.359.463l-.61.281c-2.357,1.085-3.535,1.628-4.319,1.032s-.654-1.934-.395-4.611l.067-.693a3.928,3.928,0,0,0,0-1.494,3.766,3.766,0,0,0-.841-1.208L4.333,13.7c-1.716-2.006-2.574-3.01-2.274-3.973S3.617,8.476,6.134,7.906l.651-.147A3.5,3.5,0,0,0,8.145,7.3a3.754,3.754,0,0,0,.84-1.209Z" transform="translate(-2 -2)" fill="#5eadf4"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20.466 20.466">
  <path id="star-svgrepo-com" d="M9.32,5.488C10.616,3.163,11.264,2,12.233,2s1.617,1.163,2.913,3.488l.335.6a3.754,3.754,0,0,0,.84,1.209,3.5,3.5,0,0,0,1.36.461l.651.147c2.517.569,3.775.854,4.075,1.817s-.559,1.966-2.274,3.973l-.444.519a3.767,3.767,0,0,0-.841,1.208,3.931,3.931,0,0,0,0,1.494l.067.693c.259,2.677.389,4.016-.395,4.611s-1.962.052-4.319-1.032l-.61-.281a3.491,3.491,0,0,0-1.359-.463,3.491,3.491,0,0,0-1.359.463l-.61.281c-2.357,1.085-3.535,1.628-4.319,1.032s-.654-1.934-.395-4.611l.067-.693a3.928,3.928,0,0,0,0-1.494,3.766,3.766,0,0,0-.841-1.208L4.333,13.7c-1.716-2.006-2.574-3.01-2.274-3.973S3.617,8.476,6.134,7.906l.651-.147A3.5,3.5,0,0,0,8.145,7.3a3.754,3.754,0,0,0,.84-1.209Z" transform="translate(-2 -2)" fill="#5eadf4"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20.466 20.466">
  <path id="star-svgrepo-com" d="M9.32,5.488C10.616,3.163,11.264,2,12.233,2s1.617,1.163,2.913,3.488l.335.6a3.754,3.754,0,0,0,.84,1.209,3.5,3.5,0,0,0,1.36.461l.651.147c2.517.569,3.775.854,4.075,1.817s-.559,1.966-2.274,3.973l-.444.519a3.767,3.767,0,0,0-.841,1.208,3.931,3.931,0,0,0,0,1.494l.067.693c.259,2.677.389,4.016-.395,4.611s-1.962.052-4.319-1.032l-.61-.281a3.491,3.491,0,0,0-1.359-.463,3.491,3.491,0,0,0-1.359.463l-.61.281c-2.357,1.085-3.535,1.628-4.319,1.032s-.654-1.934-.395-4.611l.067-.693a3.928,3.928,0,0,0,0-1.494,3.766,3.766,0,0,0-.841-1.208L4.333,13.7c-1.716-2.006-2.574-3.01-2.274-3.973S3.617,8.476,6.134,7.906l.651-.147A3.5,3.5,0,0,0,8.145,7.3a3.754,3.754,0,0,0,.84-1.209Z" transform="translate(-2 -2)" fill="#5eadf4"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20.466 20.466">
  <path id="star-svgrepo-com" d="M9.32,5.488C10.616,3.163,11.264,2,12.233,2s1.617,1.163,2.913,3.488l.335.6a3.754,3.754,0,0,0,.84,1.209,3.5,3.5,0,0,0,1.36.461l.651.147c2.517.569,3.775.854,4.075,1.817s-.559,1.966-2.274,3.973l-.444.519a3.767,3.767,0,0,0-.841,1.208,3.931,3.931,0,0,0,0,1.494l.067.693c.259,2.677.389,4.016-.395,4.611s-1.962.052-4.319-1.032l-.61-.281a3.491,3.491,0,0,0-1.359-.463,3.491,3.491,0,0,0-1.359.463l-.61.281c-2.357,1.085-3.535,1.628-4.319,1.032s-.654-1.934-.395-4.611l.067-.693a3.928,3.928,0,0,0,0-1.494,3.766,3.766,0,0,0-.841-1.208L4.333,13.7c-1.716-2.006-2.574-3.01-2.274-3.973S3.617,8.476,6.134,7.906l.651-.147A3.5,3.5,0,0,0,8.145,7.3a3.754,3.754,0,0,0,.84-1.209Z" transform="translate(-2 -2)" fill="#5eadf4"/>
</svg>
                            </div>
                            <div className="review-message">
                            They were patient and were able to respond and execute all the change requests quickly.
                                                        </div>

                            <div className="review-profile mt-12">
                                <div className="img">
                                <img className="h-14 w-14" alt='img' src={require('../assets/client4.jpeg')}></img>
                                </div>
                                <div>
                                  <div className="review-profile-h">
                                  Anneli Tostar
                                  </div>
                                  <div className="review-profile-p truncate">
                                  CEO & Co-Founder, Tangible
                                  </div>
                                </div>
                            </div>
                            
                        </div>
                        </DigitalAnimation>
                  </div>
              </div>
            </div>
          </div>
          </div>
          
          <div className="text-center mt-16">
          <a href="https://clutch.co/profile/dstudio-agency-0#highlights" target="_blank" className="hero-badge"><svg xmlns="http://www.w3.org/2000/svg" width="19.644" height="23.006" viewBox="0 0 19.644 23.006">
  <g id="Group_167368" data-name="Group 167368" transform="translate(-609.426 -1005.298)">
    <ellipse id="Ellipse_776" data-name="Ellipse 776" cx="3.444" cy="3.444" rx="3.444" ry="3.444" transform="translate(617.623 1013.357)" fill="#1720f5"/>
    <path id="Path_2" data-name="Path 2" d="M37.454,259a7.873,7.873,0,0,1-5.541,2.183c-4.2,0-7.22-3.358-7.22-7.891s3.022-7.891,7.387-7.891a7.9,7.9,0,0,1,5.541,2.351l.5.5,2.518-2.518-.5-.5a11.307,11.307,0,0,0-8.059-3.358C25.7,241.707,21,246.744,21,253.292s4.7,11.585,10.913,11.585a11.027,11.027,0,0,0,8.059-3.358l.5-.5L37.958,258.5Z" transform="translate(588.426 763.427)" fill="#071335"/>
  </g>
</svg>
 5.0 from over 20 Reviews</a>


 <div className="clutch-data">
    <div>
      <h2>99.9<span>%</span></h2>
      <p>Client Satisfaction</p>
    </div>

    <div>
      <h2>100<span>%</span></h2>
      <p>Value For Money</p>
    </div>


    <div>
      <h2>1<span>%</span></h2>
      <p>Chances Of Failure</p>
    </div> </div>
          </div>

        </div>
      </DigitalAnimation>
      <DigitalAnimation>

        {/* <div className="bg-[#41B87E]">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-2 mt-20 md:gap-4">
              <div className="text-left py-16 md:py-24 md:px-0">
                <div>
                  <svg
                    width="104"
                    height="36"
                    viewBox="0 0 104 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.6914 11.4101H30.6704L33.1974 26.3101C33.2601 26.1661 33.3152 26.0189 33.3624 25.8691C34.4157 21.1657 35.4707 16.4631 36.5274 11.7611C36.5594 11.6181 36.7524 11.4031 36.8714 11.4021C39.6264 11.3821 42.3804 11.3861 45.1814 11.3861L48.6134 26.2611L48.7134 26.2721L51.1874 11.4141H58.7094C58.4824 12.3251 58.2624 13.2231 58.0344 14.1141C56.3797 20.6074 54.7284 27.1017 53.0804 33.5971C52.9444 34.1371 52.7444 34.3091 52.1804 34.2971C49.4424 34.2631 46.7034 34.2821 43.8994 34.2821L40.7854 20.7281L40.6734 20.7111L37.4324 34.2831H28.4784L22.6914 11.4101Z"
                      fill="black"
                    />
                    <path
                      d="M26.702 34.2941H20.355C19.402 34.2941 18.449 34.2781 17.497 34.3021C17.3405 34.3173 17.183 34.2877 17.0426 34.2169C16.9022 34.1461 16.7848 34.037 16.704 33.9021C13.9373 30.0108 11.161 26.1261 8.375 22.2481C8.291 22.1301 8.20401 22.0151 8.04201 21.7951V34.2641H0V5.55612H8.01801V17.6761L8.11801 17.7131L9.912 15.2241C12.1467 12.1221 14.38 9.01878 16.612 5.91412C16.6888 5.78742 16.7991 5.68436 16.9308 5.61627C17.0624 5.54817 17.2102 5.51768 17.358 5.52813C20.25 5.54313 23.142 5.53713 26.151 5.53713L15.597 19.4771L26.702 34.2941Z"
                      fill="black"
                    />
                    <path
                      d="M69.4722 34.2621V4.13916H77.4652V20.3252C78.4072 18.9912 79.2652 17.7792 80.1172 16.5652C81.2522 14.9542 82.3802 13.3392 83.5272 11.7372C83.5842 11.6512 83.6578 11.5775 83.7437 11.5203C83.8295 11.4631 83.9259 11.4236 84.0272 11.4041C87.1022 11.3841 90.1782 11.3892 93.3872 11.3892L84.3712 22.8991L93.5342 34.2651C93.3172 34.2781 93.1712 34.2932 93.0252 34.2932C90.0992 34.2932 87.1732 34.2872 84.2482 34.3032C84.0919 34.3137 83.936 34.279 83.799 34.203C83.6621 34.1271 83.55 34.0133 83.4762 33.8752C81.5142 30.9392 79.5352 28.0142 77.4982 24.9932V34.2611L69.4722 34.2621Z"
                      fill="black"
                    />
                    <path
                      d="M66.932 11.4221V34.2731H58.978V11.4221H66.932Z"
                      fill="black"
                    />
                    <path
                      d="M94.1611 29.8401C94.1596 28.9169 94.4316 28.0139 94.9428 27.2452C95.4539 26.4765 96.1814 25.8764 97.0333 25.5208C97.8853 25.1651 98.8233 25.0698 99.7294 25.2469C100.635 25.424 101.469 25.8656 102.124 26.5159C102.779 27.1662 103.227 27.996 103.411 28.9006C103.595 29.8053 103.507 30.7442 103.158 31.5988C102.809 32.4534 102.215 33.1855 101.45 33.7026C100.685 34.2196 99.7843 34.4985 98.8611 34.5041C97.6194 34.5086 96.4268 34.0198 95.5454 33.1452C94.6641 32.2706 94.1662 31.0817 94.1611 29.8401Z"
                      fill="#FFD065"
                    />
                    <path
                      d="M62.9643 1.0082C63.7512 0.961064 64.537 1.11781 65.2456 1.46327C65.9542 1.80874 66.5617 2.33122 67.0093 2.9802C67.3865 3.56487 67.6072 4.23662 67.6502 4.93108C67.6932 5.62553 67.5572 6.31939 67.255 6.94615C66.9529 7.57291 66.4948 8.11156 65.9247 8.51045C65.3546 8.90935 64.6916 9.15511 63.9993 9.2242C63.0746 9.37191 62.1287 9.31316 61.2293 9.05219C60.3408 8.82173 59.5603 8.28921 59.0216 7.54601C58.483 6.8028 58.2198 5.89527 58.2773 4.97919C58.3218 4.05543 58.6801 3.1745 59.293 2.48193C59.906 1.78937 60.7368 1.32664 61.6483 1.1702C62.0853 1.0882 62.5273 1.0612 62.9643 1.0082Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="text-2xl font-medium mt-8 max-w-lg">
                  <div className="mb-4">
                  They did really great job, now our website is getting great traffic and conversion.
                  </div>
Highly appreciated and recommended.
                </div>
                <div className="mt-16">
                  <div className="text-xl font-medium">Brady Cooper
</div>
                  <p>Product Owner & Co- Founder</p>
                </div>
              </div>
              <div className="client-bg">
              <img className="md:mt-10" alt='img' src={require('../assets/IMG.png')}></img>
              </div>
            </div>
          </div>
        </div> */}
      </DigitalAnimation>

    </section>
  )

}
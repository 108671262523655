import "./App.css";
import React from 'react';

import { HeaderComponent } from "./components/header";
import { HeroComponent } from "./components/hero";
// import { Portfolio } from "./components/portfolio";
import { FooterComponent } from "./components/footer";
import { Stats } from "./components/stats";
import { MemberShip } from "./components/membership";
import { Services } from "./components/services";
import { CaseStudy } from "./components/caseStudy";
// import { Whatwedo } from "./components/whatWeDo";
import { Faq } from "./components/faq";
import { Designedfor } from "./components/designedFor";
import { WorkComponent } from "./components/work"



function HomePage() {

 

  return (
    <div className="App">
      <div>
      <HeaderComponent />
      <HeroComponent />
      </div>
     
      <div>
      <MemberShip/>
      </div>
      
      
      {/* <Portfolio /> */}
      {/* <Designedfor/> */}
      <CaseStudy/>

      <div>
      <Stats/>
      </div>
      {/* <Whatwedo/> */}
      <div>
      <Designedfor/>
      </div>
      <div className="services">
        <WorkComponent></WorkComponent>
      </div>
      <Services/>
      <div>
      <Faq/>
      </div>
      
      <FooterComponent/>

    </div>
  );
}

export default HomePage;

import Marquee from "react-fast-marquee";
import { Link } from "react-router-dom";

import slider1 from "./assets/slide-1.png";
import slider2 from "./assets/slide-2.png";
import slider3 from "./assets/slide-3.png";
import slider4 from "./assets/slide-4.png";
import slider5 from "./assets/slide-5.png";
import slider6 from "./assets/slide-6.png";
import slider7 from "./assets/slide-7.png";
export function HeroProducts(){
    let images = [
        { img: slider1, alt: "project 1", link: "" },
        { img: slider2, alt: "project 2", link: "" },
        { img: slider3, alt: "project 3", link: "" },
        { img: slider4, alt: "project 3", link: "" },
        { img: slider5, alt: "project 3", link: "" },
        { img: slider6, alt: "project 3", link: "" },
        { img: slider7, alt: "project 3", link: "" }
    
        
      ]
    return   <section className="pt-10 md:pt-16">
    <div className="-my-4 flex justify-start gap-2 overflow-hidden py-4 md:gap-6">
      <Marquee pauseOnHover='true' autoFill={true}>
        {images.map((m, i) => <div key={i} className="col-6 me-5 ">
          <Link to={m.link}>
            <img  src={m.img} alt={m.alt} className="w-auto h-60 md:h-96" ></img>
          </Link>
        </div>)}
      </Marquee>
    </div>
  </section>
}